import React from 'react';
import "./about.css"
import Slider from './Slider';
import { Link } from 'react-router-dom';

export default function Contact() {

    const PreviewContainer = ({src, title}) => {
        return (
            <div className="previewContainer">
                <img className="aboutImage" src={src}   />  

                <div className='previewTextContainer'>
                    <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit e</p>
                    <h2>{title}</h2>
                </div>
            </div>
        )
    }

    return(
        <div className='aboutPageWrapper'>
            <h1 className="aboutHeader">We in it to win it: DUB3</h1>
            
     
           {/*  <PreviewContainer src={"imgs/makava-preview.png"} title={"makava conecpt"} /> */}
            <PreviewContainer src={"imgs/fatp-preview.png"} title={"FATP"}/>
            <PreviewContainer src={"imgs/laster-preview.png"} title={"laster bags"}/>
            <PreviewContainer src={"imgs/Labelium-preview.png"} title={"Labelium Vienna"}/>

            <Slider />

                    <h1 className="heroHeadingCTA"> 
                        You have come to the right place <br />
                        this is where our journey  <br />begins...
                       {/*  <span className="textOutline">
                        </span>
                        You have come to the right place
                         <br /> this is where
                         <span className="textOutline">  </span>our journey 
                         begins <br />
                         WITH US,
                         <span className="textOutline"> 
                        </span>
                        < br /> DUB3 */}
                    </h1> 
                    
                    {/* <a className='callToActionLink' onClick={() => {playContactAnimation()}}>get in touch</a> */}
                    <a id="getInTouch" href="#" class="arrow" /* onClick={() => {playContactAnimation()}} */>get in touch
                        <span class="arrow_icon">
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#FFFFFF" stroke-width="1.5" stroke-miterlimit="16"/></svg>
                        </span>
                    </a>

                    <div className="aboutFooter">
                        <p className="copyrightNotice">©2022 Dub3 studio. All rights reserved.</p>
                        <Link className="footerLink" to="privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</Link>
                    </div>
       {/*              <footer className="dubFooter">
                        <div className="copyrightContainer">
                        </div>
                        <div className="footerLinks">
                        </div>
                    </footer>    */}
        </div>
    )
}