/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshReflectorMaterial } from '../../drei/MeshReflectorMaterial.tsx'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltfs/halfcircles.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0.03, 10.5]} scale={[-1.35, -0.03, -1.35]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials['Material.001']}>
          
        </mesh>
        <mesh geometry={nodes.Cylinder_2.geometry} material={nodes.Cylinder_2.material} />
      </group>
      <group position={[0, 0.11, 10.5]} scale={[-0.84, -0.02, -0.84]}>
        <mesh geometry={nodes.Cylinder001_1.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Cylinder001_2.geometry} material={nodes.Cylinder001_2.material} />
      </group>
      <group position={[0, 0.07, 10.5]} scale={[-1.11, -0.03, -1.11]}>
        <mesh geometry={nodes.Cylinder002_1.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Cylinder002_2.geometry} material={nodes.Cylinder002_2.material} />
      </group>
    </group>
  )
}

useGLTF.preload('/halfcircles.gltf')
