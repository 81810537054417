import React, {Component} from 'react'
import EventEmitter from "reactjs-eventemitter";

export default class Sizes extends Component {

    constructor() {

        super()

        this.width = window.innerWidth
        this.height = window.innerHeight    
        this.aspect = this.width / this.height
        this.pixelRatio = Math.min(window.devicePixelRatio, 2) 
        this.frustrum = 5

        window.addEventListener('resize', () => {
            this.width = window.innerWidth
            this.height = window.innerHeight
            this.aspect = this.width / this.height
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)
            EventEmitter.emit("resize")
        }) 
    }
}