import React, {Component} from 'react'
import Experience from '../Experience'
import * as THREE from "three"

export default class Backdrop extends Component {
    constructor() {

        super()
        
        this.experience = new Experience()
        this.scene = this.experience.scene
    
        this.setBackdrop()
    }

    setBackdrop = () => {
        //Backdrop
        this.geometry = new THREE.PlaneGeometry(100, 100)
        this.material  = new THREE.MeshStandardMaterial({
            color: '#FFFFFF'
        })

        this.backdrop = new THREE.Mesh(this.geometry, this.material)
        this.backdrop.position.z = -1
        this.backdrop.receiveShadow = true

        //Floor section
        this.geometry = new THREE.PlaneGeometry(100, 100)
        this.material  = new THREE.MeshStandardMaterial({
            color: '#FFFFFF'
        })

        this.backdropFloor = new THREE.Mesh(this.geometry, this.material)
        this.backdropFloor.position.z = -1
        this.backdropFloor.flipY = Math.PI
        this.backdropFloor.receiveShadow = true

        this.scene.add(this.backdropFloor)
    }

    resize = () => {
   
    }

    update = () => {
   
    }
}