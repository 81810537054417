export default [
    {
        name: "w3dCam",
        type: "gltfModel",
        path: "/models/w3dv1.gltf"
    },    
   /*  {
        name: "w3dEnvmap",
        type: "envMap",
        path: "/textures/",
        file: "ghost.jpg"
    },  */    
]  