import React, { Suspense, useRef, useEffect, useState,  useLayoutEffect, useContext} from "react"
import { Canvas, useThree, useFrame } from "@react-three/fiber"
import { Environment, useAnimations, useGLTF, PositionalAudio, useProgress, PerformanceMonitor, PerspectiveCamera } from "@react-three/drei"
import { EffectComposer, DepthOfField, Bloom, Noise, SSR, Vignette } from '@react-three/postprocessing'
import { BlendFunction } from "postprocessing";
import ReactGA from 'react-ga'
import { KernelSize } from 'postprocessing'
import { Perf } from 'r3f-perf';
import gsap from "gsap"
import Lottie from "lottie-react";
import axios from "axios"
import { Link } from "react-router-dom";
import { useControls } from 'leva'
import CustomCursorContext from "../CustomCursor/context/CustomCursorContext.ts";
import W3 from "../models/W3origin"

import { useSwipeable } from 'react-swipeable';

import Church from "../models/Scene"
import Camera from "../models/Camera"
import Tri from "../models/Tri"
import Wave from "../models/Wave"

import scrollDownAnimation from "../../resources/lotties/scroll-down-circle.json";

import "../../resources/styling/experienceStyling.css"
import '../../resources/styling/htmlOverlayStyling.css'
import '../../resources/styling/landingPageStyling.css'

import { FaTwitter, FaLinkedinIn, FaBehance, FaTwitterSquare } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

import useStore from "./store/useStore"

let detectScroll = true
var animationIndex = 0
var rotateEnvMap = true

var firstMove = null
var secondMove = null
var thirdMove = null
var fourthMove = null
var contactFooterTimeline = null

const cameraFov = {fov: 4.5}

let footerOpen = false

var useSSR = false

let setEnv = true

let soundCreated = false

const TRACKING_ID = "UA-248360438-1"

export default function FiberCanvas() { 

    const [dpr, setDpr] = useState(1.5)

    const saveFrames = useStore((state) => state.saveFrames)

    const startExperience = useStore((state) => state.startExperience)

    const isMobileUser = useStore((state) => state.isMobileUser)

    const createSound = useStore((state) => state.createSound)
    const playSound = useStore((state) => state.playSound)
    const setPlaySoundIsTrue = useStore((state) => state.setPlaySoundIsTrue)
    const setPlaySoundIsFalse = useStore((state) => state.setPlaySoundIsFalse)
    const setSaveFramesIsFalse = useStore((state) => state.setSaveFramesIsFalse)
    const setCreateSoundIsTrue = useStore((state) => state.setCreateSoundIsTrue)

    const sound = useRef()

    const handlers = useSwipeable({
        onSwipedUp: () => {
            if(detectScroll) {
                handleScrollDown()
            }
        },
        onSwipedDown: () => {
            if(detectScroll) {handleScrollUp()}
        },
        onSwipedRight: () => {},
        onSwipedLeft: () => {},
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
      })

      useEffect(() => {
        if(saveFrames) {
            setSaveFramesIsFalse()
        } 
       /*  setCreateSoundIsTrue() */
      })

    return (
        <div className="fiberExperienceContainer" {...handlers} 
            onWheel={event => {
                if(detectScroll){
                    if (event.nativeEvent.wheelDelta > 0) {
                        handleScrollUp()
                    } else {
                        handleScrollDown()
                    } 
                } 
            }
        }>

            <Canvas className="FiberCanvas" frameloop={ saveFrames ? "demand" :  "always" } dpr={ isMobileUser ? 1 : 0.5 } performance={{ min: 1 }} >

                {/* <PerformanceMonitor onIncline={() => setDpr(1)} onDecline={() => setDpr(0.85)} > */}

                    <Suspense fallback={<Loader />}>

                        <ScrollAnimation />
                        
                        { createSound ? 
                            <>                             
                                <PositionalAudio position={[0,1,0]} url="sounds/konsti beat.mp3" ref={sound} autoplay={playSound}/>
                                { /* playSoundFunc(sound) */ }
                            </>
                            : ''
                        }

                        {/* Lighting */}
                        
                        <Environment background={false} files="preller_drive_1k.hdr" path="/" /> 
                        <color attach="background" args={['#1f1f1f']} />

                        <Effects />

                    </Suspense>

                {/* </PerformanceMonitor> */}

            </Canvas>

            <HTMLOverlay sound={sound}/>

        </div>
    )
}

const playSoundFunc = (sound) => {

    if(sound.current !== undefined) {
        sound.current.play() 
        return
    } else {
        setTimeout(() => {
            sound.current.play() 
            console.log("after timeout")
        }, 500)
    }
}

const Effects = () => {
/*     const { enabled, ...props } = useControls({
        enabled: true,
        temporalResolve: true,
        STRETCH_MISSED_RAYS: true,
        USE_MRT: true,
        USE_NORMALMAP: true,
        USE_ROUGHNESSMAP: true,
        ENABLE_JITTERING: true,
        ENABLE_BLUR: true,
        DITHERING: false,
        temporalResolveMix: { value: 0.9, min: 0, max: 1 },
        temporalResolveCorrectionMix: { value: 0.4, min: 0, max: 1 },
        maxSamples: { value: 0, min: 0, max: 1 },
        resolutionScale: { value: 1, min: 0, max: 1 },
        blurMix: { value: 0.2, min: 0, max: 1 },
        blurKernelSize: { value: 8, min: 0, max: 8 },
        blurSharpness: { value: 0.5, min: 0, max: 1 },
        rayStep: { value: 0.43, min: 0, max: 1 },
        intensity: { value: 2, min: 0, max: 5 },
        maxRoughness: { value: 1, min: 0, max: 1 },
        jitter: { value: 0.3, min: 0, max: 5 },
        jitterSpread: { value: 0.25, min: 0, max: 1 },
        jitterRough: { value: 0.1, min: 0, max: 1 },
        roughnessFadeOut: { value: 1, min: 0, max: 1 },
        rayFadeOut: { value: 0, min: 0, max: 1 },
        MAX_STEPS: { value: 20, min: 0, max: 20 },
        NUM_BINARY_SEARCH_STEPS: { value: 6, min: 0, max: 10 },
        maxDepthDifference: { value: 5, min: 0, max: 10 },
        maxDepth: { value: 1, min: 0, max: 1 },
        thickness: { value: 3, min: 0, max: 10 },
        ior: { value: 1.45, min: 0, max: 2 }
      }) */

    return (
        <>
        <EffectComposer disableNormalPass>

            <Noise opacity={0.02} />

            <Bloom kernelSize={1} luminanceThreshold={0} luminanceSmoothing={0.3} intensity={0.2} />
            <Bloom kernelSize={KernelSize.BIG} luminanceThreshold={0} luminanceSmoothing={0.1} intensity={0.2} />

            <Vignette
                offset={0.6}
                darkness={0.55}
                eskil={true}
                blendFunction={BlendFunction.NORMAL}
            />

            {/* <DepthOfField target={[0, 0, 300 / 2]} focalLength={1} bokehScale={2} height={500}/> 
 */}
        </EffectComposer>    

        {/* <fog attach="fog" args={['purple', 1, 80]} density={0.1} /> */}
        {/* <fog attach="fog" args={['red', 0.05, 60]} density={0.01} /> */}
    </>
    )
}

let loadedCt = 0
let setLoaded = false

function Loader() {
    const fiberLoaded = useStore((state) => state.fiberLoaded)
    const setFiberLoadedIsTrue = useStore((state) => state.setFiberLoadedIsTrue)

    const { active, progress, errors, item, loaded, total } = useProgress()

    //console.log( active + " - " + progress + " - " + loaded + " total: " + total)

    if(!active) {
        loadedCt++
        //console.log(loadedCt)

        if(loadedCt >= 5 && !setLoaded) {
            setFiberLoadedIsTrue()
            console.log("loaded component")
            setLoaded = true
        } 
    }
}


{/* ---------------------------------------------------------------
    Loads the inteactive Objects in the Church & loads cameras 
    Apllies animation timelines for the second camera move
    Not Split in component because we need to access the timelines in the html
--------------------------------------------------------------- */}

let triCloseupAnimation = null
let waveCloseupAnimation = null
let animationOnePlayed = false
let animationTwoPlayed = false
let animPlaying = false
let interactiveSectionActive = false

const closeupCameraFov = {fov: 77}

function InteractiveSection() {

    const group = useRef()
    const closeUpCam = useRef()
    const interactiveScene = useRef()

    var { animations } = useGLTF('/gltfs/closeupcam.glb')
    var { actions } = useAnimations(animations, interactiveScene) 

    const[triggerCam , setTriggerCam] = useState(true)

    const setuseLerpIsTrue = useStore((state) => state.setuseLerpIsTrue)
    const setuseLerpIsFalse = useStore((state) => state.setuseLerpIsFalse)

    const setuse3DLerpIsTrue = useStore((state) => state.setuse3DLerpIsTrue)
    const setuse3DLerpIsFalse = useStore((state) => state.setuse3DLerpIsFalse)

    const {setType} = useContext(CustomCursorContext)

    useEffect(() => {

      actions['Action.005'].loop = false

      
    let mediaControl = gsap.matchMedia()

    {/* DESKTOP */}
    mediaControl.add("(min-width: 480px)", () => {
        waveCloseupAnimation = new gsap.timeline({ defaults: {duration: 2},
            onStart: function() {
                animPlaying = true
                detectScroll = false
                actions['Action.005'].play()
                setuse3DLerpIsFalse()
                setTriggerCam(false)
            }, onComplete: function() {
                animPlaying = false
                animationOnePlayed = true
                actions['Action.005'].paused = true
            }, onReverseComplete: function() {
                animPlaying = false
                animationOnePlayed = false
                detectScroll = true
                actions['Action.005'].paused = true
                setuse3DLerpIsTrue()
                setTriggerCam(true)
            }, onUpdate: function() {
                closeUpCam.current.fov = closeupCameraFov.fov
                closeUpCam.current.updateProjectionMatrix()
            }, 
        paused: true})    

        waveCloseupAnimation
            .fromTo(actions['Action.005'], {time: 0}, {time: 1.5})
            .to(".tapToInteractNotice", {opacity: 0, duration: 0.5}, '0')
            .fromTo(closeupCameraFov, {fov: 77}, {fov: 30, duration: 1}, '0')
            .fromTo("#soundText", {x: "100%", opacity: 0}, {x: 0, opacity: 1, duration: 0.5}, '1')
            .fromTo(".audioControls", {opacity: 1, display: 'flex'}, {opacity: 0,  display: 'none', duration: 0.25}, '0')

        triCloseupAnimation = new gsap.timeline({ defaults: {duration: 2},
            onStart: function() {
                animPlaying = true
                detectScroll = false
                actions['Action.005'].play()
                setuse3DLerpIsFalse()
                setTriggerCam(false)
            }, onComplete: function() {
                animPlaying = false
                animationTwoPlayed = true
                actions['Action.005'].paused = true
            }, onReverseComplete: function() {
                animPlaying = false
                animationTwoPlayed = false
                detectScroll = true
                actions['Action.005'].paused = true
                setuse3DLerpIsTrue()
                setTriggerCam(true)
            }, onUpdate: function() {
                closeUpCam.current.fov = closeupCameraFov.fov
                closeUpCam.current.updateProjectionMatrix()
            },  
        paused: true})

        triCloseupAnimation
            .fromTo(actions['Action.005'], {time: 2.5}, {time: 4})
            .to(".tapToInteractNotice", {opacity: 0, duration: 0.5}, '0')
            .fromTo(closeupCameraFov, {fov: 77}, {fov: 30, duration: 1}, '0')
            .fromTo("#threeText", {x: "100%", opacity: 0}, {x: 0, opacity: 1, duration: 0.5}, '1')
            .fromTo(".audioControls", {opacity: 1, display: 'flex'}, {opacity: 0,  display: 'none', duration: 0.25}, '0')

    })

    {/* MOBILE */}
    mediaControl.add("(max-width: 480px)", () => {
        waveCloseupAnimation = new gsap.timeline({ defaults: {duration: 1.5},
            onStart: function() {
                animPlaying = true
                detectScroll = false
                actions['Action.005'].play()
                setuse3DLerpIsFalse()
                setTriggerCam(false)
            }, onComplete: function() {
                animPlaying = false
                animationOnePlayed = true
                actions['Action.005'].paused = true
            }, onReverseComplete: function() {
                animPlaying = false
                animationOnePlayed = false
                detectScroll = true
                actions['Action.005'].paused = true
                setuse3DLerpIsTrue()
                setTriggerCam(true)
            }, onUpdate: function() {
                closeUpCam.current.fov = closeupCameraFov.fov
                closeUpCam.current.updateProjectionMatrix()
            }, 
          paused: true})    
    
        waveCloseupAnimation
        .fromTo(actions['Action.005'], {time: 0}, {time: 0.9})
        .to(".tapToInteractNotice", {opacity: 0, duration: 0.5}, '0')
        .fromTo(closeupCameraFov, {fov: 110}, {fov: 40, duration: 1}, '0')
        .fromTo("#soundText", {y: "100%", opacity: 0}, {y: 0, opacity: 1, duration: 0.5}, '1')
    
        triCloseupAnimation = new gsap.timeline({ defaults: {duration: 1.5},
            onStart: function() {
                animPlaying = true
                detectScroll = false
                actions['Action.005'].play()
                setuse3DLerpIsFalse()
                setTriggerCam(false)
            }, onComplete: function() {
                animPlaying = false
                animationTwoPlayed = true
                actions['Action.005'].paused = true
            }, onReverseComplete: function() {
                animPlaying = false
                animationTwoPlayed = false
                detectScroll = true
                actions['Action.005'].paused = true
                setuse3DLerpIsTrue()
                setTriggerCam(true)
            }, onUpdate: function() {
                closeUpCam.current.fov = closeupCameraFov.fov
                closeUpCam.current.updateProjectionMatrix()
            },  
          paused: true})
    
        triCloseupAnimation
            .fromTo(actions['Action.005'], {time: 2.5}, {time: 3.4})
            .to(".tapToInteractNotice", {opacity: 0, duration: 0.5}, '0')
            .fromTo(closeupCameraFov, {fov: 110}, {fov: 40, duration: 1}, '0')
            .fromTo("#threeText", {y: "100%", opacity: 0}, {y: 0, opacity: 1, duration: 0.5}, '1') 
    })


    })

    return (
        <group ref={interactiveScene}>
            <Camera makeDefault={triggerCam} />

            <group ref={group} dispose={null}>
                <group name="Scene">
                    <PerspectiveCamera name="Camera001" ref={closeUpCam} makeDefault={!triggerCam} far={1000} near={0.1} fov={77} position={[-0.01, 0.66, 5.21]} rotation={[2.94, 0, 3.14]} />
                </group>
            </group>

            {/* Wave with HITBOX */}
            <Wave />
            <mesh position={[1.23, 0.81, 8.17]} rotation={[0, -Math.PI / 4, 0]} scale={0.5} 
               /*  onPointerEnter={()=>{
                    if(!animPlaying && interactiveSectionActive) {
                        setType("navLink")
                    }
                }}
                onPointerLeave={()=>{
                    setType("default")
                }} */
                onClick={(e) => {
                    if(!animPlaying && interactiveSectionActive){
                        if(!animationOnePlayed && !animationTwoPlayed) {
                            waveCloseupAnimation.play()
                        } else if(animationOnePlayed) {
                            animPlaying = true
                            waveCloseupAnimation.reverse(0)
                        }
                    }
                }}>
                <boxBufferGeometry />
                <meshBasicMaterial visible={false}/>
            </mesh>

            {/* Tri with HITBOX */}
            <Tri />
            <mesh position={[-1.23, 0.81, 8.17]} rotation={[0, -Math.PI / 4, 0]} scale={0.5} 
                /* onPointerEnter={()=>{setType("navLink")}}
                onPointerLeave={()=>{setType("default")}}    */         
                onClick={(e) => {  
                if(!animPlaying && interactiveSectionActive){
                    if(!animationOnePlayed && !animationTwoPlayed) { 
                        triCloseupAnimation.play()
                    } else if(animationTwoPlayed) {
                        animPlaying = true
                        triCloseupAnimation.reverse(0)
                    }
                }
            }}>
                <boxBufferGeometry />
                <meshBasicMaterial visible={false}/>
            </mesh>

        </group>
    )
}

const playContactAnimation = () => {

    setEnv = false

    if(footerOpen) {
        contactFooterTimeline.reverse()
        footerOpen = false
    } else {
        contactFooterTimeline.play()
        footerOpen = true
    }
}

const closeContactAnimation = () => {
    contactFooterTimeline.reverse(0)
}

const closeCookieNotice = (consent) => {
    
    localStorage.consent = consent

    if(consent) {
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    document.querySelector(".cookieNoticeContainer").classList.remove("animateCookieNotice")
    gsap.fromTo(".cookieNoticeContainer", { right: '0', duration: 1}, { right: '-100%', duration: 1})
}

function HTMLOverlay(...props) {
    const {setType, type} = useContext(CustomCursorContext)


    const playSound = useStore((state) => state.playSound)
    const setPlaySoundIsTrue = useStore((state) => state.setPlaySoundIsTrue)
    const setPlaySoundIsFalse = useStore((state) => state.setPlaySoundIsFalse)

    const pauseOrResumeAudio = () => { 

        const sound = {...props[0].sound}
        const aduioBars = 5

        if(sound.current.isPlaying) {
            sound.current.pause()
            setPlaySoundIsFalse()

            for(let i = 1; i <= aduioBars; i++) {
                document.querySelector('.playing__bar'+i).classList.remove('is_playing')
                document.querySelector('.playing__bar'+i).classList.add('not_playing')
            }

        } else {
            setPlaySoundIsTrue()
            sound.current.play()

            for(let i = 1; i <= aduioBars; i++) {
                document.querySelector('.playing__bar'+i).classList.add('is_playing')
                document.querySelector('.playing__bar'+i).classList.remove('not_playing')
            }
        }
    }

    return (
        <>
            <div className="dub3NavLinksContainer">
                <a className="mainHeaedrLink" /* href="https://dub3-d880a4.webflow.io/" target="_blank" rel="noopener noreferrer"  */
                    onMouseEnter={() => setType("navLink")}
                    onMouseLeave={() => setType("default")}><span className="dubDash">/</span>DUB3</a>

                {/* <a class="hover-link" area-label="/ABOUT" href="https://dub3-d880a4.webflow.io/about" target="_blank" rel="noopener noreferrer" id="aboutLink" 
                    onMouseEnter={() => setType("navLink")}
                    onMouseLeave={() => setType("default")}>  
                    <span area-hidden="true">
                        <span>/ABOUT<span>/ABOUT</span></span>
                    </span>
                </a> */}

                <Link class="hover-link" area-label="/CONTACT" cursor-type="button" id="contactLink"  
                    onClick={() => { playContactAnimation()}} 
                    onMouseEnter={() => setType("navLink")}
                    onMouseLeave={() => setType("default")}>  
                    <span area-hidden="true">
                        <span>/CONTACT<span>/CONTACT</span></span>
                    </span>
                </Link> 
            </div>


            <div className="htmlOverlay">
                <section className='landingSection ' id="sectionOne">

                    <div class="line">
                        <h1 className="heroHeading fadeInUp">WHERE WORLDS COLLIDE</h1> 
                    </div>

                    <div class="line">
                        <h2 className="heroSubHeading fadeInUp">MERGE WEB AND 3D WITH US, DUB3</h2> 
                    </div>

                    <div className="landingScrollToPlayNoticeWrapper">
                        <div className="scrollToPlayNoticContainer">
                            <Lottie className="videoScrollAnimationLanding" animationData={scrollDownAnimation} loop={true} />  
                        </div>
                    </div>

                 </section>
            </div>

            <section className='landingSection ' id="sectionThree">   
                    <h2 className='sectionTitle'>
                        what we on
                    </h2> 

                    <h1 id="threeHeadingTitle" className="sectionHeading">Not the conventional design studio</h1> 

                    <p id="threeHeadingText" className='sectionText'> 
                       {/*  combining their skills and to merge 3D, motion, concepts and sounddesign to create the unseen.
                        Stick out of the crowd and create memorable web experiences with us! */}
                            Poised to create the unseen, we bring the world of 3D and web together. Our mission is to craft digital experiences designed to grab  the users attention and leave a memorable impression.<br /> <br />
                            Web technology moves fast and we move with it by exploring the limits of 3D and web. Breathe life into your website and let it be your companies movie screen.
                            {/* Poised to create the unseen, dub3 is a digital design studio elevating your brand by building upon emerging technologies in web & 3D. We rejuvenate leading names and give rise to emerging contenders. Our mission is to craft digital explorations designed to grab attention & provoke emotions, making your corporation stand out for good, in an ever evolving digital landscape.  */}
                        <br />
                    </p>

                   {/*  <div className="detailsSectionContainer">
                    
                        <div className="detailsSection">
                            <p className='sectionText'> 
                                Interactive engaging content
                            </p>
                        </div>
                        <div className="detailsSection">
                            <p className='sectionText'> 
                                Simple to complex 3D scenes
                            </p>
                        </div>
                        <div className="detailsSection">
                            <p className='sectionText'> 
                                Single, multipagers & webshops
                            </p>
                        </div>
                    
                    </div> */}
            </section>

            <section className='landingSection ' id="sectionFour">   
                    <h1 className="heroHeadingCTA"> 
                        You have come to the right place <br />
                        this is where our journey  <br />begins...
                       {/*  <span className="textOutline">
                        </span>
                        You have come to the right place
                         <br /> this is where
                         <span className="textOutline">  </span>our journey 
                         begins <br />
                         WITH US,
                         <span className="textOutline"> 
                        </span>
                        < br /> DUB3 */}
                    </h1> 
                    
                    {/* <a className='callToActionLink' onClick={() => {playContactAnimation()}}>get in touch</a> */}
                    <a id="getInTouch" href="#" class="arrow" onClick={() => {playContactAnimation()}} 
                    onMouseEnter={() => setType("navLink")}
                    onMouseLeave={() => setType("default")}
                    >get in touch
                        <span class="arrow_icon">
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#FFFFFF" stroke-width="1.5" stroke-miterlimit="16"/></svg>
                        </span>
                    </a>
            </section>

            <div className="cookieNoticeContainer">
                <p>We use coookies to give you the best experience on our site <Link className='privacy-link' target="_blank" rel="noopener noreferrer" to="privacy-policy"> privacy policy</Link></p>
                <div className='cookieLinkContainer'>  
                    <a className='callToActionLink' onClick={() => {closeCookieNotice(true)}}>accept</a> 
                    <a className='callToActionLink' onClick={() => {closeCookieNotice(false)}}>decline</a>
                </div>
            </div>

            <nav id="dub3NavContainer">

            <div className="audioControls" onClick={() => {pauseOrResumeAudio()}} 
            onMouseEnter={() => setType("navLink")}
            onMouseLeave={() => setType("default")}>
            
                <span class="playing__bar playing__bar1 is_playing"></span>
                <span class="playing__bar playing__bar2 is_playing"></span>
                <span class="playing__bar playing__bar3 is_playing"></span>
                <span class="playing__bar playing__bar4 is_playing"></span>
                <span class="playing__bar playing__bar5 is_playing"></span>
            </div>

           {/*  <span className='dub3NavLink'>Contact</span> */}
{/* 
                <div className="dub3NavLinksContainer">

                    <Link className="mainHeaedrLink" to="/fiber"><span className="dubDash">/</span>DUB3</Link>

                    <Link class="hover-link" area-label="/ABOUT" cursor-type="button" to="/about">
                        <span area-hidden="true">
                            <span>/ABOUT<span>/ABOUT</span></span>
                        </span>
                    </Link>

                    <Link class="hover-link" area-label="/CONTACT" cursor-type="button"  to="/contact">
                        <span area-hidden="true">
                            <span>/CONTACT<span>/CONTACT</span></span>
                        </span>
                    </Link>

                </div> */}

                <div className='closeIconContainer2' id="closeIconTheSecond" onClick={() => { closeFooter() }} 
                    /* onMouseEnter={() => setType("navLink")}
                    onMouseLeave={() => setType("default")} */>
                    <GrClose className='closeIcon'/>
                </div> 

                <div className='socialIconContainer'> 
                    {/* <FaTwitter className='footerSocialIcon'/>
                    <FaBehance className='footerSocialIcon'/> */}
                    <a href="https://www.linkedin.com/company/dub3-studio/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn className='footerSocialIcon' />
                    </a>

                </div> 

                <div className="indexContainer">
                    <span class="dot dotActive"></span>
                    <span class="dot dotInactive"></span>
                    <span class="dot dotInactive"></span>
                    <span class="dot dotInactive"></span>
                    <span class="dot dotInactive"></span>
                </div>

            </nav> 

            <div className="interactiveSectionOverlay" id="soundText">
                <div className="closeIconHitbox">
                    <GrClose className='textCloseIcon' onClick={(e) => {
                    if(!animPlaying && interactiveSectionActive){
                        if(!animationOnePlayed && !animationTwoPlayed) {
                            waveCloseupAnimation.play()
                        } else if(animationOnePlayed) {
                            animPlaying = true
                            waveCloseupAnimation.reverse(0)
                        }
                    }
                    }} />
                </div>
              

                <div className="interactiveSectionTextContainer">
                    <h2 className="interactiveContainerSubHeading">/SOUNDDESIGN</h2>
                    <h1 className="sectionHeading">The cherry on top</h1>
                    <p className="sectionText">
                        Sound design on an interactive website is an often overlooked but important aspect of user experience. In today's world, where attention spans are short and competition for users' time is fierce, having a well-designed and engaging website is crucial. Sound design can play a key role in making a website stand out and keeping users engaged. <br /><br />
                        One of the key benefits of incorporating sound design into a website is that it can help to create a more immersive and engaging experience for the user. By carefully crafting a unique sound for a brand, a website can create a specific emotional response in the user that is associated with that brand.<br /><br />
                    </p>
                </div>

               {/*  <div className="intSectNav">
                    <p>getintouch@dub3.studio</p>
                    <p>©2022</p>
                </div> */}
            </div>

            <div className="interactiveSectionOverlay" id="threeText">
                <div className="closeIconHitbox"  
                    /* onMouseEnter={() => setType("navLink")}
                    onMouseLeave={() => setType("default")} */>
                    <GrClose className='textCloseIcon' onClick={(e) => {
                    if(!animPlaying && interactiveSectionActive){
                        if(!animationOnePlayed && !animationTwoPlayed) { 
                            triCloseupAnimation.play()
                        } else if(animationTwoPlayed) {
                            animPlaying = true
                            triCloseupAnimation.reverse(0)
                        }
                    }
                    }} 
                   />
                </div>

                <div className="interactiveSectionTextContainer">
                    <h2 className="interactiveContainerSubHeading">/3D Design</h2>
                    <h1 className="sectionHeading">spice it up</h1>
                    <p className="sectionText">
                        3D integration in websites is the next step in web design because it allows for a more immersive and interactive user experience. With the increasing popularity of virtual and augmented reality technology, 3D models on the web allow for a more realistic and engaging way to showcase products.<br />  <br /> 
                        In addition to improving the user experience, 3D integration can also be a powerful tool for businesses to showcase their products in a more engaging and memorable way. By using 3D models, businesses can differentiate themselves from competitors and create a more visually appealing and interactive product presentation. This can help to increase customer interest and ultimately drive sales.
                    </p>
                </div>

                {/* <div className="intSectNav">
                    <p>getintouch@dub3.studio</p>
                    <p>©2022</p>
                </div> */}
            </div>

            <footer className="dubFooter">
                <div className="copyrightContainer">
                    <p className="copyrightNotice">©2022 Dub3 studio. All rights reserved.</p>
                </div>
                <div className="footerLinks">
                    <Link className="footerLink" to="privacy-policy" target="_blank" rel="noopener noreferrer" >privacy policy</Link>
                </div>
            </footer>   
        </>
    )
}

const closeFooter = () => {
    contactFooterTimeline.reverse()
}

let rerenderCt = 0

function ScrollAnimation() {

    const setSaveFramesIsTrue = useStore((state) => state.setSaveFramesIsTrue)
    const setSaveFramesIsFalse = useStore((state) => state.setSaveFramesIsFalse)

    const setuseLerpIsTrue = useStore((state) => state.setuseLerpIsTrue)
    const setuseLerpIsFalse = useStore((state) => state.setuseLerpIsFalse)

    const setuse3DLerpIsTrue = useStore((state) => state.setuse3DLerpIsTrue)
    const setuse3DLerpIsFalse = useStore((state) => state.setuse3DLerpIsFalse)

    const {setType} = useContext(CustomCursorContext)

    var scene = useRef()

    var { animations } = useGLTF('/gltfs/camera.gltf')
    var { actions } = useAnimations(animations, scene) 

    var actionsCamera = actions 

    var { animations } = useGLTF('/gltfs/doors.glb')
    var { actions } = useAnimations(animations, scene)

    var actionsDoors = actions

    var { animations } = useGLTF('/gltfs/fontsingletteranimation.gltf')
    var { actions } = useAnimations(animations, scene)

    var actionsFont = actions

    var { animations } = useGLTF('/gltfs/digitaldesignstudio.gltf')
    var { actions } = useAnimations(animations, scene)

    var actionsCompany = actions

    var { animations } = useGLTF('/gltfs/journeytxt.glb')
    var { actions } = useAnimations(animations, scene)

    var churchTextAction = actions

    const { camera } = useThree()

    let rotSpd = 0.001

    useFrame(() => {
        if(scene !== undefined) {
            if(rotateEnvMap) {
                scene.current.rotation.y += rotSpd
            }
        }
    })  
    
    useEffect(() => {

        rerenderCt++
        
       if(actionsCamera['Action'] !== undefined) {

            actionsCamera['Action'].loop = false

            actionsDoors['door bottom leftAction'].loop = false
            actionsDoors['door bottom rightAction'].loop = false  

            actionsFont['Action.006'].loop = false
            actionsFont['Action.007'].loop = false
            actionsFont['Action.008'].loop = false
            actionsFont['Action.009'].loop = false

            actionsCompany['Action.003'].loop = false

            churchTextAction['Action.004'].loop = false
        }  

        if(rerenderCt <= 2) {
            CreateTimelines(actionsCamera, actionsDoors, actionsFont, actionsCompany, churchTextAction, scene, camera)

            // * Open Footer animation
            // -----------------------------------------------------------------
            contactFooterTimeline = new gsap.timeline({
                onStart: function() { setSaveFramesIsTrue()}, 
                onComplete: function() {setSaveFramesIsTrue()}, 
                onReverseComplete: function() {setSaveFramesIsFalse()}, 
                paused: true})

            contactFooterTimeline
                .to(".transitionSlide", {top: "0vh", duration: .5, ease: "expo.out"})
                .to(".contactFooterContainer", {top: "0vh", duration: 1, ease: "expo.out"})
                .to(".dub3NavLink", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".indexContainer", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".audioControls", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".socialIconContainer", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
                .to("#closeIconTheSecond", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
                .fromTo(".contactHeadline", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'})
                .fromTo(".footerFormContainer", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'}) 
                .to(".main-cursor", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out"}) 
                .to(".secondary-cursor", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out"}) 
                //.fromTo(".dubFooter", {opacity: 1, visibility: "visible"}, {opacity: 0, visibility: "hiddden" , duration: .5, ease: "power1.out"}) 

            // * first animation 
            // -----------------------------------------------------------------
            firstMove = new gsap.timeline({defaults: {duration: 1.16, ease: "Power1.inOut"}, 
            onStart: function (){
                actionsCamera['Action'].play()
                actionsFont['Action.006'].play()
                actionsFont['Action.007'].play()    
                actionsFont['Action.008'].play()
                actionsFont['Action.009'].play()
                actionsCompany['Action.003'].play()
            },
            onComplete: function () {
                actionsCamera['Action'].paused = true
                actionsFont['Action.006'].paused = true
                actionsFont['Action.007'].paused = true
                actionsFont['Action.008'].paused = true
                actionsFont['Action.009'].paused = true
                actionsCompany['Action.003'].paused = true
                detectScroll = true
                setuseLerpIsTrue()            
            },
            onReverseComplete: function () {
                actionsCamera['Action'].paused = true
                actionsFont['Action.006'].paused = true
                actionsFont['Action.007'].paused = true
                actionsFont['Action.008'].paused = true
                actionsFont['Action.009'].paused = true
                actionsCompany['Action.003'].paused = true
                detectScroll = true
                setuseLerpIsFalse()            
            },
            onUpdate: function() {
                camera.fov = cameraFov.fov
                camera.updateProjectionMatrix()
            },  
            paused: true
            })
            firstMove
                .to(".line .fadeInUp", .2, {
                    y: 105,
                    opacity: 0,
                    ease: "power4.out",
                    delay: 0,
                    skewY: 7,
                    stagger: {
                        amount: 0.1
                    }
                })
                .to("#sectionOne", {opacity: 0, duration: 0.5}, '0.2')
                .to("#dub3NavContainer", {opacity: 1, duration: 0.5}, '0.2')
                .fromTo(actionsCamera['Action'], {time: 0}, {time: 0.917}, '0')
                .fromTo(actionsFont['Action.006'], {time: 0}, {time: 1.16}, '0')
                .fromTo(actionsFont['Action.007'], {time: 0}, {time: 1.16}, '0')
                .fromTo(actionsFont['Action.008'], {time: 0}, {time: 1.16}, '0')
                .fromTo(actionsFont['Action.009'], {time: 0}, {time: 1.16}, '0')
                .fromTo(actionsCompany['Action.003'], {time: 0}, {time: 1.16}, '0')
                .fromTo(cameraFov, {fov: 4.5}, {fov: 77, duration: 0.623}, '0.333')
                .to(".htmlOverlay", {visibility: "hidden", duration: 0}, '1.16')

        // * third animation 
        // -----------------------------------------------------------------
        thirdMove = new gsap.timeline({defaults: {duration: 3.65}, 
            onStart: function (){
                actionsCamera['Action'].play()
                actionsDoors['door bottom leftAction'].play()
                actionsDoors['door bottom rightAction'].play()  
                churchTextAction['Action.004'].play()  
                interactiveSectionActive = false
            },
            onComplete: function () {
                actionsCamera['Action'].paused = true
                actionsDoors['door bottom leftAction'].paused = true
                actionsDoors['door bottom rightAction'].paused = true  
                churchTextAction['Action.004'].paused = true
                detectScroll = true
                useSSR = true
                interactiveSectionActive = true
                setuseLerpIsFalse()  
                setuse3DLerpIsTrue() 
                console.log("ITSS THE §RD")
            },
            onReverseComplete: function () {
                actionsCamera['Action'].paused = true
                actionsDoors['door bottom leftAction'].paused = true
                actionsDoors['door bottom rightAction'].paused = true 
                churchTextAction['Action.004'].paused = true
                detectScroll = true
                setuseLerpIsTrue()  
                setuse3DLerpIsFalse() 
            }, onUpdate: function() {
                interactiveSectionActive = false
            },
            paused: true
        })  
        thirdMove
            .to("#sectionThree", {opacity: 0, duration: .333})
            .fromTo(actionsCamera['Action'], {time: 2.7916}, {time: 6.2}, '0')
            .fromTo(actionsDoors['door bottom leftAction'], {time: 2.7916}, {time: 6.2}, '0')
            .fromTo(actionsDoors['door bottom rightAction'], {time: 2.7916}, {time: 6.2}, '0')  
            //.fromTo(churchTextAction['Action.004'], {time: 2.7916}, {time: 6.2}, '0')  
            //.to("#sectionChurch", {visibility: "visible", duration: 0}, '0')
            //.to("#sectionChurch", {opacity: 1, duration: 0.3})
            .to(".tapToInteractNotice", {opacity: 1, duration: 0.5}, '2')
            .to("#sectionThree", {visibility: "hidden", duration: 0})

        } 

      /*   if(firstMove === null && secondMove === null && thirdMove === null && fourthMove === null ) {
            console.log("render case 2")
            CreateTimelines(actionsCamera, actionsDoors, actionsFont, actionsCompany, churchTextAction, scene, camera)
        } */

      
    })

    return (
        <group ref={scene}>
            {/* <CreateTimelines actions={actionCamera} actionsDoors={actionsDoors} actionsFont={actionsFont} actionsCompany={actionsCompany} churchTextAction={churchTextAction} scene={scene} camera={camera}/> */}
            <InteractiveSection />
            <Church/>

            <W3 
                onPointerEnter={()=>{setType("navLink")}}
                onPointerLeave={()=>{setType("default")}}
                onPointerDown={()=>{rotSpd = 0.012}}
                onPointerUp={()=>{rotSpd = 0.0025}}
            />
        </group>
    )
}

function CreateTimelines (actionsCamera, actionsDoors, actionsFont, actionsCompany, churchTextAction, scene, camera) {


/* 
    const setuseLerpIsTrue = useStore((state) => state.setuseLerpIsTrue)
    const setuseLerpIsFalse = useStore((state) => state.setuseLerpIsFalse)

    const setuse3DLerpIsTrue = useStore((state) => state.setuse3DLerpIsTrue)
    const setuse3DLerpIsFalse = useStore((state) => state.setuse3DLerpIsFalse) */

 /*    useEffect(() => {
        console.log("logged")
    }) */

    let mediaControl = gsap.matchMedia()

    //Desktop
    mediaControl.add("(min-width: 480px)", () => {
        
        // * second animation
        // -----------------------------------------------------------------    
        secondMove = new gsap.timeline({defaults: {duration: 1.8, ease: "Power1.inOut"}, 
            onStart: function (){
                actionsCamera['Action'].play()
                actionsFont['Action.006'].play()
                actionsFont['Action.007'].play()
                actionsFont['Action.008'].play()
                actionsFont['Action.009'].play()
                actionsCompany['Action.003'].play()
                rotateEnvMap = false
            },
            onComplete: function () {
                actionsCamera['Action'].paused = true
                actionsFont['Action.006'].paused = true
                actionsFont['Action.007'].paused = true
                actionsFont['Action.008'].paused = true
                actionsFont['Action.009'].paused = true
                actionsCompany['Action.003'].paused = true
                detectScroll = true
                rotateEnvMap = false
            },
            onReverseComplete: function () {
                actionsCamera['Action'].paused = true
                actionsFont['Action.006'].paused = true
                actionsFont['Action.007'].paused = true
                actionsFont['Action.008'].paused = true
                actionsFont['Action.009'].paused = true
                actionsCompany['Action.003'].paused = true
                detectScroll = true
                rotateEnvMap = true
            },
            onUpdate: function() {
                camera.fov = cameraFov.fov
                camera.updateProjectionMatrix()
            },  
            paused: true
        })
        secondMove
            //.to("#sectionTwo", {opacity: 0, duration: 0})
            .fromTo(actionsCamera['Action'], {time: 1}, {time: 2.7916}, '0')
            .fromTo(actionsFont['Action.006'], {time: 1.16}, {time: 2.7916}, '0.2')
            .fromTo(actionsFont['Action.007'], {time: 1.16}, {time: 2.7916}, '0.2')
            .fromTo(actionsFont['Action.008'], {time: 1.16}, {time: 2.7916}, '0.2')
            .fromTo(actionsFont['Action.009'], {time: 1.16}, {time: 2.7916}, '0.2')
            .fromTo(actionsCompany['Action.003'], {time: 1.16}, {time: 2.7916}, '0.2')
            .to(scene.current.rotation, {y: 0, duration: 0}, '0.2')   
            .to("#sectionThree", {visibility: "visible", opacity: 1, duration: 0}, '.5')
            .fromTo(".sectionTitle", {opacity: 0, x: -60}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.3'})
            .fromTo("#threeHeadingTitle", {opacity: 0, x: -60}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.35'})
            .fromTo("#threeHeadingText", {opacity: 0, x: -60}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.4'})
            //.to("#sectionThree", {opacity: 1, duration: .333}, '1')
 




        // * fourth animation
        // -----------------------------------------------------------------
        fourthMove = new gsap.timeline({defaults: {duration: 2.8, ease: "Power1.inOut"}, 
            onStart: function (){
                actionsCamera['Action'].play()
                actionsDoors['door bottom leftAction'].play()
                actionsDoors['door bottom rightAction'].play()
                churchTextAction['Action.004'].play()  
                interactiveSectionActive = false
            },
            onComplete: function () {
                actionsCamera['Action'].paused = true
                actionsDoors['door bottom leftAction'].paused = true
                actionsDoors['door bottom rightAction'].paused = true 
                churchTextAction['Action.004'].paused = true
                detectScroll = true
            },
            onReverseComplete: function () {
                actionsCamera['Action'].paused = true
                actionsDoors['door bottom leftAction'].paused = true
                actionsDoors['door bottom rightAction'].paused = true 
                churchTextAction['Action.004'].paused = true
                detectScroll = true
                interactiveSectionActive = true

            },
            onUpdate: function() {
                camera.fov = cameraFov.fov
                camera.updateProjectionMatrix()
            },  
            paused: true
        })
        fourthMove
            .fromTo(actionsDoors['door bottom leftAction'], {time: 6.45}, {time: 7.9, duration: 0})
            .fromTo(actionsDoors['door bottom rightAction'], {time: 6.45}, {time: 7.9, duration: 0})  
            //.fromTo(churchTextAction['Action.004'], {time: 6.6}, {time: 7.9, duration: 1})  
            .fromTo(actionsCamera['Action'], {time: 6.6}, {time: 7.9}, '0')
            .to(".tapToInteractNotice", {opacity: 0, duration: 0.5}, '0')
            //.fromTo(actionsFont['Action'], {time: 6.6}, {time: 7.9})
            .to("#sectionFour", {opacity: 1, visibility: "visible", duration: .333} )
            .fromTo(".heroHeadingCTA", {opacity: 0, x: -50}, {opacity: 1, x: 0, duration: .5, ease: "power1.out",})
            .fromTo("#getInTouch", {opacity: 0, x: -50}, {opacity: 1, x: 0, duration: .5, ease: "power1.out"}) 
            .to(".dubFooter", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out"}) 
    })

    //Mobile
    mediaControl.add("(max-width: 480px)", () => {  

    // * second animation
    // -----------------------------------------------------------------    
    secondMove = new gsap.timeline({defaults: {duration: 1.8, ease: "Power1.inOut"}, 
        onStart: function (){
            actionsCamera['Action'].play()
            actionsFont['Action.006'].play()
            actionsFont['Action.007'].play()
            actionsFont['Action.008'].play()
            actionsFont['Action.009'].play()
            actionsCompany['Action.003'].play()
            rotateEnvMap = false
        },
        onComplete: function () {
            actionsCamera['Action'].paused = true
            actionsFont['Action.006'].paused = true
            actionsFont['Action.007'].paused = true
            actionsFont['Action.008'].paused = true
            actionsFont['Action.009'].paused = true
            actionsCompany['Action.003'].paused = true
            detectScroll = true
            rotateEnvMap = false
        },
        onReverseComplete: function () {
            actionsCamera['Action'].paused = true
            actionsFont['Action.006'].paused = true
            actionsFont['Action.007'].paused = true
            actionsFont['Action.008'].paused = true
            actionsFont['Action.009'].paused = true
            actionsCompany['Action.003'].paused = true
            detectScroll = true
            rotateEnvMap = true
        },
        onUpdate: function() {
            camera.fov = cameraFov.fov
            camera.updateProjectionMatrix()
        },  
        paused: true
    })
    secondMove
        //.to("#sectionTwo", {opacity: 0, duration: 0})
        .fromTo(actionsCamera['Action'], {time: 1}, {time: 2.7916}, '0')
        .to(cameraFov, {fov: 110, duration: 1}, '0.5')
        .fromTo(actionsFont['Action.006'], {time: 1.16}, {time: 2.7916}, '0.2')
        .fromTo(actionsFont['Action.007'], {time: 1.16}, {time: 2.7916}, '0.2')
        .fromTo(actionsFont['Action.008'], {time: 1.16}, {time: 2.7916}, '0.2')
        .fromTo(actionsFont['Action.009'], {time: 1.16}, {time: 2.7916}, '0.2')
        .fromTo(actionsCompany['Action.003'], {time: 1.16}, {time: 2.7916}, '0.2')
        .to(scene.current.rotation, {y: 0, duration: 0}, '0.2')        
        .to("#sectionThree", {visibility: "visible", opacity: 1, duration: 0}, '.5')
        .fromTo(".sectionTitle", {opacity: 0, x: -60}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.3'})
        .fromTo("#threeHeadingTitle", {opacity: 0, x: -60}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.35'})
        .fromTo("#threeHeadingText", {opacity: 0, x: -60}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.4'}) 

    // * third animation 
    // -----------------------------------------------------------------
    thirdMove = new gsap.timeline({defaults: {duration: 3.65}, 
        onStart: function (){
            actionsCamera['Action'].play()
            actionsDoors['door bottom leftAction'].play()
            actionsDoors['door bottom rightAction'].play()  
            churchTextAction['Action.004'].play()  
            interactiveSectionActive = false
        },
        onComplete: function () {
            actionsCamera['Action'].paused = true
            actionsDoors['door bottom leftAction'].paused = true
            actionsDoors['door bottom rightAction'].paused = true  
            churchTextAction['Action.004'].paused = true
            detectScroll = true
            useSSR = true
            interactiveSectionActive = true
        },
        onReverseComplete: function () {
            actionsCamera['Action'].paused = true
            actionsDoors['door bottom leftAction'].paused = true
            actionsDoors['door bottom rightAction'].paused = true 
            churchTextAction['Action.004'].paused = true
            detectScroll = true
        }, 
        onUpdate: function() {
            interactiveSectionActive = false
        },
        paused: true
    })  
    thirdMove
        .to("#sectionThree", {opacity: 0, duration: .333})
        .fromTo(actionsCamera['Action'], {time: 2.7916}, {time: 6.2}, '0')
        .fromTo(actionsDoors['door bottom leftAction'], {time: 2.7916}, {time: 6.2}, '0')
        .fromTo(actionsDoors['door bottom rightAction'], {time: 2.7916}, {time: 6.2}, '0')  
        .to(".tapToInteractNotice", {opacity: 1, duration: 0.5}, '2')
        //.fromTo(churchTextAction['Action.004'], {time: 2.7916}, {time: 6.2}, '0')  
        //.to("#sectionChurch", {visibility: "visible", duration: 0}, '0')
        //.to("#sectionChurch", {opacity: 1, duration: 0.3})
        .to("#sectionThree", {visibility: "hidden", duration: 0})

    // * fourth animation
    // -----------------------------------------------------------------
    fourthMove = new gsap.timeline({defaults: {duration: 2.8, ease: "Power1.inOut"}, 
        onStart: function (){
            actionsCamera['Action'].play()
            actionsDoors['door bottom leftAction'].play()
            actionsDoors['door bottom rightAction'].play()
            churchTextAction['Action.004'].play()  
            interactiveSectionActive = false
        },
        onComplete: function () {
            actionsCamera['Action'].paused = true
            actionsDoors['door bottom leftAction'].paused = true
            actionsDoors['door bottom rightAction'].paused = true 
            churchTextAction['Action.004'].paused = true
            detectScroll = true
        },
        onReverseComplete: function () {
            actionsCamera['Action'].paused = true
            actionsDoors['door bottom leftAction'].paused = true
            actionsDoors['door bottom rightAction'].paused = true 
            churchTextAction['Action.004'].paused = true
            detectScroll = true
            interactiveSectionActive = true
        },
        onUpdate: function() {
            camera.fov = cameraFov.fov
            camera.updateProjectionMatrix()
        },  
        paused: true
    })
    fourthMove
        .fromTo(actionsDoors['door bottom leftAction'], {time: 6.45}, {time: 7.9, duration: 0})
        .fromTo(actionsDoors['door bottom rightAction'], {time: 6.45}, {time: 7.9, duration: 0})  
        //.fromTo(churchTextAction['Action.004'], {time: 6.6}, {time: 7.9, duration: 1})  
        .fromTo(actionsCamera['Action'], {time: 6.6}, {time: 7.9}, '0')
        .to(".tapToInteractNotice", {opacity: 0, duration: 0.5}, '0')

        //.fromTo(actionsFont['Action'], {time: 6.6}, {time: 7.9})
        .to("#sectionFour", {opacity: 1, visibility: "visible", duration: .333} )
        .fromTo(".heroHeadingCTA", {opacity: 0, x: -50}, {opacity: 1, x: 0, duration: .5, ease: "power1.out",})
        .fromTo("#getInTouch", {opacity: 0, x: -50}, {opacity: 1, x: 0, duration: .5, ease: "power1.out"}) 
        .to(cameraFov, {fov: 75, duration: .5}, '0.5')
        .to(".dubFooter", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out"}) 

    })
    return(<></>)
}


function handleScrollUp() {
    if(animationIndex > 0) {
      detectScroll = false
      animationIndex-- 

      document.querySelector(".dotActive").classList.add("dotInactive")
      document.querySelector(".dotActive").classList.remove("dotActive")
      const query = ".dot:nth-of-type(" + (animationIndex + 1)  + ")"
      document.querySelector(query).classList.add("dotActive")

      switch (animationIndex) {
        case 0:
          firstMove.reverse()
        break;
        case 1:
          secondMove.reverse()
        break;
        case 2:
          thirdMove.reverse()
        break;
        case 3:
          fourthMove.reverse()
        break;
        default: break;
      }
    }
}

function handleScrollDown() {
    if(animationIndex < 4) {
      detectScroll = false
      animationIndex++

      const query = ".dot:nth-of-type(" + (animationIndex + 1) + ")"
    
      document.querySelector(".dotActive").classList.remove("dotActive")
      document.querySelector(query).classList.add("dotActive")
      document.querySelector(query).classList.remove("dotInactive")

      switch (animationIndex) { 
        case 1:
          firstMove.play()
        break;
        case 2:
          secondMove.play()
        break;
        case 3:
          thirdMove.play()
        break;
        case 4:
          fourthMove.play()
        break;
        default: break;
      }
    }
}
