import React, {useEffect} from "react"
import "./pagesStyling.css"
import {GoDeviceDesktop} from "react-icons/go"
import useStore from "../web3D/store/useStore"

const DesktopNotice = () => {

    const setFiberLoadedIsTrue = useStore((state) => state.setFiberLoadedIsTrue)

    useEffect(() => {
        setFiberLoadedIsTrue()
    })

    return (
        <div className="noticeWrapper">
            <GoDeviceDesktop className="noticeIcon"/> <br />
            <h1>Switch to Desktop</h1>
            <p>The mobile version of dub3 is currently under development, please switch to the desktop version to enjoy the experience</p>
        </div>
    )
}

export default DesktopNotice