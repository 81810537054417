import create from 'zustand';

const useStore = create((set) => ({
    isMobileUser: false,
        setMobileUserIsTrue: () => set((state) => ({ isMobileUser: true })), 
        setMobileUserIsFalse: () => set((state) => ({ isMobileUser: false })), 
    animationIndex: 0,
        increaseAnimationIndex: () => set((state) => ({ animationIndex: state.animationIndex + 1})), 
        decreaseAnimationIndex: () => set((state) => ({ isMobileUser: state.animationIndex - 1 })), 
    fiberLoaded: false,
        setFiberLoadedIsTrue: () => set((state) => ({ fiberLoaded: true })), 
        setFiberLoadedIsFalse: () => set((state) => ({ fiberLoaded: false })), 
    startExperience: false,
        setStartExperienceIsTrue: () => set((state) => ({ startExperience: true })), 
        setstartExperienceIsFalse: () => set((state) => ({ startExperience: false })), 
    saveFrames: true,
        setSaveFramesIsTrue: () => set((state) => ({ saveFrames: true })), 
        setSaveFramesIsFalse: () => set((state) => ({ saveFrames: false })), 
    screenSpaceReflections: true,
        setScreenSpaceReflectionsIsTrue: () => set((state) => ({ screenSpaceReflections: true })), 
        setScreenSpaceReflectionsIsFalse: () => set((state) => ({ screenSpaceReflections: false })), 
    createSound: false,
        setCreateSoundIsTrue: () => set((state) => ({ createSound: true })), 
        setCreateSoundIsFalse: () => set((state) => ({ createSound: false })), 
    playSound: true,
        setPlaySoundIsTrue: () => set((state) => ({ playSound: true })), 
        setPlaySoundIsFalse: () => set((state) => ({ playSound: false })), 
    useLerp: false,
        setuseLerpIsTrue: () => set((state) => ({ useLerp: true })), 
        setuseLerpIsFalse: () => set((state) => ({ useLerp: false })), 
    use3DLerp: false,
        setuse3DLerpIsTrue: () => set((state) => ({ use3DLerp: true })), 
        setuse3DLerpIsFalse: () => set((state) => ({ use3DLerp: false })), 
    contactFooterIsOpen: false,
        setContactFooterIsOpenTrue: () => set((state) => ({ contactFooterIsOpen: true })), 
        setContactFooterIsOpenFalse: () => set((state) => ({ contactFooterIsOpen: false })), 
    triggerCam: true,
        setTriggerCamIsTrue: () => set((state) => ({ triggerCam: true })), 
        setTriggerCamIsFalse: () => set((state) => ({ triggerCam: false })), 

}));

export default useStore;    