import React, { useRef, useState, useEffect } from 'react'
import { Stars, useAnimations, useGLTF, PerspectiveCamera, Html } from "@react-three/drei"
import { useFrame, useThree } from '@react-three/fiber'
import gsap from 'gsap'

import './intOverlay.css'

import Camera from "./Camera"
import Churchbase from "./Churchbaseblack"
import TowerEmission from "./Toweremission"
import BaseEmission from "./Churchemission"

import W3 from "./W3origin"
import Wave from "./Wave"
import Doors from "./Doors"
import Halfcircles from "./Halfcircles.js"
import Couches from "./Couches"
import Dub3font from "./Fontsingletteranimation"
import SecondFrametxt from "./SecondFrameTxt"
import Company from "./Digitaldesignstudio"
import Churchtext from "./Churchtext_v2"
import Glass from "./Glass"
import Emissiontower from "./Emissiontower"
import Journeytxt from "./Journeytxt"
import Tri from "./Tri"
import Plate from "./Plate"
import useStore from '../web3D/store/useStore'

import { Perf } from 'r3f-perf'

import { MeshReflectorMaterial } from '../../drei/MeshReflectorMaterial.tsx'

export default function Scene({...props }) {
    
    const group = useRef()
    const closeUpCam = useRef()

    const isMobileUser = useStore((state) => state.isMobileUser)

    return (
      <>
        <group ref={group} {...props} dispose={null}>

            <Churchbase />

            <BaseEmission />
            <Emissiontower />

                    <Plate />
                
                <Dub3font /> 
                <Company />
                <Journeytxt />
                
            <Doors />
            <Couches />

            {/* <Perf /> */}

            <Halfcircles />
        
            <Ground /> 
            <Stars saturation={0.5} count={200} speed={1} />

        </group>
      </>
    )
}

function Ground(props) {
    return (
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.02, 5]}>
        <planeGeometry args={[13, 16]} />
        <MeshReflectorMaterial
          blur={[400, 100]}
          resolution={1024}
          mixBlur={0.3}
          opacity={1}
          depthScale={2}
          minDepthThreshold={0.6}
          maxDepthThreshold={2}
          roughness={2}
        />
      </mesh>
    )
  }