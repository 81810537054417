import React, {Component} from 'react'
import Experience from '../Experience'
import * as THREE from "three"

export default class W3D extends Component {
    constructor() {

        super()
        
        this.experience = new Experience()
        this.time = this.experience.time
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.w3dObject = this.resources.items.w3dCam
        this.w3d = this.w3dObject.scene
    
        this.setModel()

        //this.setAnimation()
    }

    setModel = () => {
        // * 3D object set shadows
        console.log( this.w3d )
        this.w3d.children.forEach(child => {
            child.castShadow = true
            child.receiveShadow = true

            if(child instanceof THREE.Group){
                child.children.forEach((groupchild => {
                    groupchild.castShadow = true
                    groupchild.receiveShadow = true
                }))
            }

            // -------------------------------------
            // Different Materials unused code

            // * Glass effect
            /* child.material = new THREE.MeshPhysicalMaterial()
            child.material.roughness = 0
            child.material.metalness = 1
            child.material.color.set(0xFFFFFF)
            child.material.ior = 3
            child.material.transmission = 1
            child.material.opacity = 1
            
            child.material.envMap = this.resources.items.w3dEnvmap
            child.material.envMapIntensity = 3 */

            // * image texture
 /*            const texture = new THREE.TextureLoader().load( '/resources/texture/somenotnone-texture.png' )
            texture.encoding = THREE.sRGBEncoding
            child.material = new THREE.MeshBasicMaterial( { map: texture } )  */


            // * Video texture
            // just load mp4 video from assets 

            /* child.material = new THREE.MeshBasicMaterial({ 
                map: this.resources.items.screen 
            }) */

            // -------------------------------------
            // * Environment map 
            /* child.material = new THREE.MeshBasicMaterial({ 
                envMap: this.resources.items.w3dEnvmap
            }) */
            
        })

        this.scene.add(this.w3d)
        this.w3d.scale.set(10,10,10)

        this.w3d.position.y = -1
    }
    
    setAnimation = () => {
        this.mixer = new THREE.AnimationMixer(this.w3d)
        this.anmimation = this.mixer.clipAction(this.w3d.animations[0])
        this.adnimation.play()
    }

    update = () => {
        this.mixer.update(this.time.delta)
        this.w3d.rotation.x += 0.001
    }
}