/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Model({ ...props }) {
  const group = useRef()
  const w3 = useRef()
  const { nodes, materials } = useGLTF('/gltfs/wave.glb')

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    w3.current.rotation.y = 0.5 + Math.sin(t / 3)
    w3.current.position.y = 0.9 + (Math.sin(t / 2) / 20)
  })

  return (
    <group ref={group} {...props} dispose={null} >
      <mesh ref={w3} geometry={nodes.wave.geometry} material={materials['Material.007']} position={[1.23, 0.84, 8.17]} rotation={[0, Math.PI / 4, 0]} scale={0.03} />
    </group>
  )
}

useGLTF.preload('/gltfs/wave.glb')
