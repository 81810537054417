import React, {Component} from 'react'
import * as THREE from "three"
import EventEmitter from "reactjs-eventemitter"

import Experience from '../Experience'
import Environment from './Environment'
import Cube from './Cube'
import W3D from './W3d'
import Controls from './Controls'
import Backdrop from './Backdrop'

export default class World extends Component {

    constructor() {

        super()

        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.camera = this.experience.camera
        this.resources = this.experience.resources

        EventEmitter.subscribe('ready', event => { 
            
            this.environment = new Environment()
            this.w3D = new W3D()
            // this.backdrop = new Backdrop()
            this.controls = new Controls()

            // * grabbing cam from blender scene
            //console.log(this.scene.children[7].children[1])
        })

    }

    resize = () => {

    }

    update = () => {
        // * Calls update of 3D object to play animation
        /* if (this.w3D) {
            this.w3D.update()
        } */

        if (this.controls) {
            this.controls.update()
        } 
    }
}