import React, {Component} from 'react'
import * as THREE from "three"

import Experience from './Experience'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera extends Component {

    constructor() {

        super()

        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.resources = this.experience.resources
        //this.blenderOrthoCam = this.resources.items.w3dCam

        this.createPerspectiveCamera()
        this.createOrthographicCamera()
        this.setOrbitControls()
    }

    createPerspectiveCamera = () => {
        this.perspectiveCamera = new THREE.PerspectiveCamera(3, this.sizes.aspect, 0.01, 1000)

        this.perspectiveCamera.position.x = 0
        this.perspectiveCamera.position.y = 3
        this.perspectiveCamera.position.z = 0

        // this.perspectiveCamera.position.z = 0.25

        this.scene.add(this.perspectiveCamera)
    }

    createOrthographicCamera = () => {
        this.frustrum = this.sizes.frustrum
        this.orthographicCamera = new THREE.OrthographicCamera(
            (-this.sizes.aspect * this.frustrum) / 2,
            (this.sizes.aspect * this.frustrum) / 2,
            this.frustrum / 2,
            -this.frustrum / 2,
            -10,
            10
        )

        this.scene.add(this.orthographicCamera)

  /*    this.helper = new THREE.CameraHelper( this.orthographicCamera )
        this.scene.add( this.helper )  */

        // * Add helper grid & axis
        /*  const size = 20;
        const divisions = 20;

        const gridHelper = new THREE.GridHelper( size, divisions );
        this.scene.add( gridHelper );

        const axesHelper = new THREE.AxesHelper( 5 );
        this.scene.add( axesHelper ); */  
    }

    setOrbitControls = () => {
        this.controls = new OrbitControls(this.perspectiveCamera, this.canvas)
        this.controls.enableDamping = true
        this.controls.enableZoom = false
    }

    resize = () => {
        // * Updating Perspective
        this.perspectiveCamera.aspect = this.sizes.aspect
        this.perspectiveCamera.updateProjectionMatrix()

        this.orthographicCamera.left = (-this.sizes.aspect * this.sizes.frustrum) / 2
        this.orthographicCamera.right = (this.sizes.aspect * this.sizes.frustrum) / 2
        this.orthographicCamera.top = this.frustrum / 2
        this.orthographicCamera.bottom = -this.frustrum / 2
        this.orthographicCamera.updateProjectionMatrix()
    }

    update = () => {
        //sconsole.log(this.perspectiveCamera.position)
        this.controls.update()
     /*    this.helper.matrixWorldNeedsUpdate = true
        this.helper.update() */

/*         this.helper.position.copy(this.orthographicCamera.position)
        this.helper.position.copy(this.orthographicCamera.rotation) */
    }
}