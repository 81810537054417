import React, { useState } from "react";
import CustomCursorContext, {
  CursorLookType,
} from "../context/CustomCursorContext.ts";  {/* @ts-ignore */}

const CustomCursorManager = ({ children }) => {
  const [type, setType] = useState<CursorLookType>("default");

  return (
    <CustomCursorContext.Provider value={{ type, setType }}>
      {children}
    </CustomCursorContext.Provider>
  );
};

export default CustomCursorManager;