import React, {Component} from 'react'
import Experience from '../Experience'
import * as THREE from "three"
import {gsap, ScrollTrigger} from 'gsap/all'
import gsapCore from 'gsap/gsap-core'

export default class Controls extends Component {

    constructor() {

        super()

        gsap.registerPlugin(ScrollTrigger)

        this.experience = new Experience()
        this.time = this.experience.time
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.world = this.experience.world

        this.w3D = this.world.w3D.w3d.children[2]
        this.perspectiveCamera = this.camera.perspectiveCamera

        this.lookAtPosition = new THREE.Vector3(0,0,0)

        this.setScrollTrigger()
    }


    setScrollTrigger = () => {

        this.position = this.perspectiveCamera.position
        this.rotation = this.perspectiveCamera.rotation

        this.progress = {}
        this.progress.fov = 3
        
        ScrollTrigger.matchMedia({
            //Desktop TWEENS
            "(min-width: 480px)": () => {
      
            // ----------------
            // * FIRST camera move 
            // ----------------
                this.firstMoveTimeline = new gsap.timeline({})
                
                this.firstMoveTimeline
                .to(this.position, {
                    x: 0, y: 0, z: 10,
                    duration: 2,
                    onUpdate: () => {
                        this.perspectiveCamera.lookAt(0, 0, 0)
                    },
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: .5,
                    } 
                })

            // ----------------
            // Fade out Text 
            // ----------------
                /* .to(".hero-main-title", {
                    opacity: 0,
                    scale: 1.5,
                    duration: 1,
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: true,
                    } 
                }) */
                
            // ----------------
            // Change FOV
            // ----------------
                .to(this.progress, {
                    fov: 35,
                    duration: 2,
                    onUpdate: () => {
                        this.perspectiveCamera.fov = this.progress.fov
                        this.perspectiveCamera.updateProjectionMatrix()
                    },
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: true,
                        scrub: 1,
                    } 
                })

            // ----------------
            // * SECOND camera move 
            // ----------------
                this.secondMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: "#second-move",
                        markers: false,
                        start: "50% bottom",
                        end: "bottom bottom",
                        scrub: 1.5,
                    } 
                })
                    
                this.secondMoveTimeline 

                .fromTo("#second-move", {opacity: 0}, {opacity: 1, duration: 1, 
                    scrollTrigger: {
                        trigger: "#second-move",
                        markers: true,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: 1.5,
                    } })

                .to(this.w3D.position, {
                        x: -.25, y: .1, z: 0,
                        duration: 2,
                        onUpdate: () => {
                            this.perspectiveCamera.lookAt(0, 0, 0)
                        },
                        scrollTrigger: {
                            trigger: "#second-move",
                            markers: false,
                            start: "50% bottom",
                            end: "bottom bottom",
                            scrub: .5,
                        } 
                    }) 
                .to(this.w3D.rotation, {
                    y: 6.2,
                    duration: 2,
                    onUpdate: () => {
                        this.perspectiveCamera.lookAt(0, 0, 0)
                    },
                    scrollTrigger: {
                        trigger: "#second-move",
                        markers: false,
                        start: "50% bottom",
                        end: "bottom bottom",
                        scrub: 1.5,
                    } 
                }).fromTo(".dub3Logo", { left: -30, opacity: 0}, { left: 30, opacity: 1})
                .fromTo(".dub3NavLink", { left: -60, opacity: 0}, { left: 0, opacity: 1})
            },

            
            //Mobile TWEENS
           /*  "(max-width: 479px)": () => {
                this.firstMoveTimeline = new gsap.timeline({})

                this.firstMoveTimeline

            // ----------------
            // First camera move 
            // ----------------
                .to(this.position, {
                    x: 0, y: 0, z: 10,
                    duration: 2,
                    onUpdate: () => {
                        this.perspectiveCamera.lookAt(0, 0, 0)
                    },
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: .5,
                    } 
                })

            // ----------------
            // Fade out Text 
            // ----------------
                .to(".hero-main-title", {
                    opacity: 0,
                    scale: 1.5,
                    duration: 1,
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: true,
                    } 
                })
                
            // ----------------
            // Change FOV
            // ----------------
                .to(this.progress, {
                    fov: 35,
                    duration: 2,
                    onUpdate: () => {
                        this.perspectiveCamera.fov = this.progress.fov
                        this.perspectiveCamera.updateProjectionMatrix()
                    },
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: 1,
                    } 
                })
            }, */

            all: () => {
            
            // ----------------
            // * 1ST animation 
            // ----------------
            this.firstMoveTimeline = new gsap.timeline({})
            
            this.firstMoveTimeline
            
            // ----------------
            // First camer move
            // ----------------
                .to(this.position, {
                    x: 0, y: 0, z: 10,
                    duration: 2,
                    onUpdate: () => {
                        this.perspectiveCamera.lookAt(0, 0, 0)
                    },
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: .5,
                    } 
                })

            // ----------------
            // Fade out Text 
            // ----------------
                /* .to(".hero-main-title", {
                    opacity: 1,
                    scale: 1.5,
                    duration: 1,
                    scrollTrigger: {
                        scrub: true,
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: .5,
                    } 
                }) */
                
            // ----------------
            // Change FOV
            // ----------------
                .to(this.progress, {
                    fov: 35,
                    duration: 2,
                    onUpdate: () => {
                        this.perspectiveCamera.fov = this.progress.fov
                        this.perspectiveCamera.updateProjectionMatrix()
                    },
                    scrollTrigger: {
                        trigger: ".first-move",
                        markers: false,
                        start: "top bottom",
                        end: "bottom bottom",
                        scrub: 1,
                    } 
                })
            },
        },)
    }

    resize = () => {}
    
    update = () => {

    }
}