/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/gltfs/churchtext.gltf')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh name="curchtext001" geometry={nodes.curchtext001.geometry} material={materials['Material.008']} position={[-0.87, 0.5, 5.94]} rotation={[Math.PI / 2, 0, Math.PI]} scale={0} />
      </group>
    </group>
  )
}

useGLTF.preload('/churchtext_v2.gltf')
