import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

localStorage.setItem('renderAdmin', false);
localStorage.setItem('showVideo', true);

root.render(
  //<React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  //</React.StrictMode>,
);