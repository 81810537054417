import React, {Component} from 'react'

import EventEmitter from "reactjs-eventemitter";

export default class Time extends Component  {

    constructor() {

        super()

        this.start = new Date()
        this.current = this.start
        this.elapsed = 0
        this.delta = 16

        this.update()
    }

    update = () => {
        const currentTime = Date.now()

        this.delta = currentTime - this.current
        this.current = currentTime 
        this.elapsed = this.current - this.start

        EventEmitter.emit("update")
        window.requestAnimationFrame(() => this.update())
    }
}