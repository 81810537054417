/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useEnvironment } from '@react-three/drei'

const texturePath = './somenotnone-texture.hdr'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltfs/w3origin.gltf')

  const envMap = useEnvironment({files: "/w3envr.hdr"});
  //const envMap = useEnvironment({files: "/w3silver.jpg"});

  const properties = ({
    roughness: 0.1,
    metalness: 1.1,
    envMapIntensity: 1,
    envMap: envMap,
  })

  const callout = () => {
    console.log("fire")
  }

  return (
    <group ref={group} {...props} dispose={null} onClick={() => {callout()}}>
      <mesh geometry={nodes.backupsvgsubdivided002.geometry} material={materials['Material.002']} position={[0, 17.51, 0]} rotation={[-Math.PI, 0, -Math.PI]} scale={2.37}>
        <meshStandardMaterial 
            {...properties}
          />
      </mesh>
    </group>
  )
}

useGLTF.preload('/w3origin.gltf')
