/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, Suspense } from 'react'
import { useGLTF, useVideoTexture, useTexture } from '@react-three/drei'
import { Fa500Px } from 'react-icons/fa'
import useStore from '../web3D/store/useStore'

const url = "/gmintros/churchmov.mp4"
const urlFallback = "/w3placeholder.png"

export default function Model({ ...props }) {
  const video = useRef()
  
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltfs/churchbaseblack.gltf')

  const isMobileUser = useStore((state) => state.isMobileUser)

  return (
    <group ref={group} {...props} dispose={null}>
      {/* <mesh geometry={nodes.floor.geometry} material={materials['Material.019']} position={[0, 0.03, 2.73]} scale={[3.64, 3.65, 2.78]} /> */}
      <mesh geometry={nodes.tower.geometry} material={materials['Material.005']} position={[0, 4.87, 0]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.Cube020.geometry} material={materials['Material.005']} position={[0, 2.67, 1.51]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
   
    <Suspense fallback={<FallbackMaterial url={urlFallback} />}>
        <VideoMaterial url={url} ref={video}/>
    </Suspense>
      
      { /* isMobileUser ? '' :
      <Suspense fallback={<FallbackMaterial url={url}/>}>
       
  </Suspense> */ }
    </group>
  )
}


function VideoMaterial({ url }) {
  const texture = useVideoTexture(url)
  return (
    <mesh position={[0, 1.37, 10.28]} rotation={[Math.PI, 0, -Math.PI]} >
        <planeGeometry args={[4, 2.8]} />
        <meshBasicMaterial map={texture} toneMapped={false} />
    </mesh>
  )
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url)
  return (
    <mesh position={[0, 1.35, 10.28]} rotation={[Math.PI, 0, -Math.PI]} >
      <planeGeometry args={[4, 2.7]} />
      <meshBasicMaterial map={texture} toneMapped={false} />
    </mesh>
  )
}


useGLTF.preload('/churchbaseblack.gltf')
