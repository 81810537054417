import React, {useEffect} from "react"
import "./pagesStyling.css"
import {TbError404} from "react-icons/tb"
import useStore from "../web3D/store/useStore"

const NoMatch = () => {

    const setFiberLoadedIsTrue = useStore((state) => state.setFiberLoadedIsTrue)

    useEffect(() => {
        setFiberLoadedIsTrue()
    })

    return (
        <div className="noticeWrapper">
            <TbError404 className="noticeIcon"/> <br />
            <h1>Page not found</h1>
            <p>Seems like the link you were searching for doesn't exit</p>
        </div>
    )
}

export default NoMatch