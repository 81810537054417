import React, {useEffect, useContext} from 'react';
import "../../resources/styling/htmlOverlayStyling.css"
import { Link } from 'react-router-dom';
import gsap from "gsap"
import axios from "axios"
import { GrClose } from 'react-icons/gr';
import useStore from "../web3D/store/useStore"
import '../../resources/styling/htmlOverlayStyling.css'
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext.ts';
import Lottie from "lottie-react"

import tapAnimationData from "../../resources/lotties/84088-tapping.json";

let contactFooterTimeline = null
let footerOpen = false

export default function Navigation() {

    const setSaveFramesIsTrue = useStore((state) => state.setSaveFramesIsTrue)
    const setSaveFramesIsFalse = useStore((state) => state.setSaveFramesIsFalse)
    const {setType, type} = useContext(CustomCursorContext)

    console.log(setType, type)

    let mediaControl = gsap.matchMedia()
    

    useEffect(() => {
        /* Timeline for Desktop */
        mediaControl.add("(min-width: 480px)", () => {

            contactFooterTimeline = new gsap.timeline({
                onStart: function() { console.log("save frames"); setSaveFramesIsTrue()}, 
                onComplete: function() {isFooterOpen = true}, 
                onReverseComplete: function() {setSaveFramesIsFalse()}, 
                paused: true})
   
            contactFooterTimeline
                .to(".transitionSlide", {top: "0vh", duration: .5, ease: "expo.out"})
                .to(".contactFooterContainer", {top: "0vh", duration: 1, ease: "expo.out"})
                .to(".dub3NavLink", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".indexContainer", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".audioControls", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".socialIconContainer", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".closeIconContainer", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
                .fromTo(".contactHeadline", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'})
                /* .fromTo(".footerFormContainer", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'})  */
                //.to(".dubFooter", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out"}) 
                .to(".main-cursor", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out"}) 
                .to(".secondary-cursor", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out"}) 
        })

        /* TImeline for Mobile */  	    
        mediaControl.add("(max-width: 480px)", () => {
            contactFooterTimeline = new gsap.timeline({
                onComplete: function() {isFooterOpen = true}, 

                /* onStart: function() {setSaveFramesIsTrue()}, 
                onReverseComplete: function() {setSaveFramesIsFalse()},  */
                paused: true})
   
           contactFooterTimeline
                .to(".transitionSlide", {top: "0vh", duration: .5, ease: "expo.out"})
                .to(".contactFooterContainer", {top: "0vh", duration: 1, ease: "expo.out"})
                .to(".dub3NavLink", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".indexContainer", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
                //.to(".audioControls", {opacity: 0, duration: .5, ease: "power1.out", delay: '-1'})
                .to(".socialIconContainer", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
                .to(".closeIconContainer", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
                .fromTo(".contactHeadline", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'})
                /* .fromTo(".footerFormContainer", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'})  */
                .to(".dubFooter", {opacity: 1, visibility: "visible", y: "25px" , duration: .5, ease: "power1.out"}) 
                .to(".main-cursor", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out"}) 
                .to(".secondary-cursor", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out"}) 
        })
  
    })

    return (
        <div className='navigationOverlay'>
            
            <div className="tapToInteractNotice">
                {/* <Lottie className="tapAnimationTwo" animationData={tapAnimationData} loop={true} />     */}
                <img src={"objects-icon.png"}  className="tapAnimationTwo"/>
                tap to interact
            </div>


            <section className="dubContactWrapper">

                <div className='transitionSlide' />
                <div className='contactFooterContainer'>

                    <h1 className='contactHeadline'>MERGE WEB AND 3D WITH US, DUB3 </h1>

                    <div className="footerFormContainer"> 

                    {/*     <div>
                            <p>INQUIRIES</p>
                            <br></br>
                        </div>  */}

                        <div className="footerFormElementContainer">
                            {/* <div className="contactFormElement">

                                <form className="dubFormContainer" onSubmit={(e) => {handleSubmit(e)}}>

                                    <input type="email" name="email" placeholder='E-Mail'></input> 
                                    <textarea required name="inqMessage" rows="5" cols="25" placeholder='leave a message'></textarea> 
                                    <button disabled className='formInputButton'>send request</button>
                                    <p className='successMessage'>Thank you for your inquiry, we'll be in touch shortly!</p>
                                </form> 
                            </div> */}

                            <div className="contactFormElement">
                               {/*  <p>Email us</p> */}
                                <a className="emailLink" href="mailto:inquiries@dub3.studio">getintouch@dub3.studio</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='closeIconContainer' onClick={() => { closeFooter() }}>
                    <GrClose className='closeIcon'/>
                 </div>
            </section> 
        </div>
    )
}

let isFooterOpen = false

const closeFooter = () => {
    contactFooterTimeline.reverse()
    footerOpen = false
}


const playContactAnimation = () => {


    if(footerOpen) {
        contactFooterTimeline.reverse()
        footerOpen = false
    } else {
        contactFooterTimeline.play()
        footerOpen = true
    }
}
const handleSubmit = (event) => {

    event.preventDefault();

    const email = event.target.elements.email.value
    const msg = event.target.inqMessage.value         

    axios.post('https://fatp-crud-api.herokuapp.com/inq', {service: "Cover", email: email, message: msg}); 

    document.querySelector(".successMessage").classList.add("active")
}
