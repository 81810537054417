import React from "react"
import "./pagesStyling.css"

export default function Agb() {
    return(
        <div className="subPageContainer">
            <p>
                Allgemeine Geschäftsbedingungen (AGB) <br />
                Herzlich willkommen bei dub3.studio! <br /><br />

                Diese Allgemeinen Geschäftsbedingungen enthalten die Regeln und Bestimmungen für die Nutzung von Dub3's Website unter https://www.dub3.studio/.  <br /><br />

                Wir gehen davon aus, dass Sie bei Zugriff auf die Website die Allgemeinen Geschäftsbedingungen in vollem Umfang akzeptieren. Bitte fahren Sie mit der Nutzung von dub3.studio nicht fort, sofern Sie nicht mit allen auf dieser Seite aufgeführten Bedingungen einverstanden sind. <br /><br />

                Cookies:  <br /><br />
                Die Website verwendet Cookies, um Ihr Online-Erlebnis zu personalisieren. Durch weiteren Zugriff auf dub3.studio stimmen Sie der Verwendung von Cookies zu.  <br /><br />

                Ein Cookie ist eine Textdatei, die von einem Webserver auf Ihrer Festplatte gespeichert wird. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie enthalten einen eindeutigen Identifikator und können nur von dem Webserver der Domain gelesen werden, die das Cookie bei Ihnen gespeichert hat.  <br /><br />

                Wir verwenden Cookies zu unterschiedlichen Zwecken, unter anderem zur Analyse und für personalisierte Marketing-Mitteilungen mit dem Ziel, die Benutzerfreundlichkeit unserer Website zu verbessern. Sie haben die Möglichkeit, optionale Cookies abzulehnen. Einige Cookies sind jedoch für das einwandfreie und ordnungsgemäße Funktionieren unserer Website unbedingt notwendig. Solche technisch notwendigen Cookies müssen immer aktiviert werden und erfordern daher keiner Einwilligung. Beachten Sie bitte, dass Sie durch das Akzeptieren der erforderlichen Cookies auch Cookies von Drittanbietern akzeptieren, sofern Sie von Drittanbietern auf unserer Website bereitgestellte Dienste wie z. B. ein integriertes Videoanzeigefenster nutzen.

                Lizenz:
                Sofern nicht anderweitig angegeben haben Dub3 und/oder seine Lizenzgeber die geistigen Eigentumsrechte für alle Materialien auf dub3.studio. Alle Rechte am geistigen Eigentum sind vorbehalten. Sie können Seiten von dub3.studio für Ihren eigenen persönlichen Gebrauch ansehen und/oder ausdrucken, vorbehaltlich der in diesen Allgemeinen Geschäftsbedingungen festgelegten Einschränkungen.

                Folgendes ist untersagt:

                Veröffentlichung des Materials von dub3.studio
                Verkauf, Vermietung oder Unterlizenzierung des Materials von dub3.studio
                Reproduktion, Vervielfältigung oder Kopie des Materials von dub3.studio
                Weiterverbreitung der Inhalte von dub3.studio
                Diese Vereinbarung tritt mit dem heutigen Datum in Kraft.

                In gewissen Bereichen dieser Website haben Nutzer die Möglichkeit, Kommentare zu Zwecken des Meinungs- und Informationsaustausches zu hinterlassen. Entsprechende Nutzerkommentare werden vor ihrer Veröffentlichung auf der Website von Dub3 nicht überprüft, gefiltert, bearbeitet oder verbreitet. Nutzerkommentare spiegeln ausschließlich die persönliche Meinung des jeweiligen Verfassers wider und entsprechen nicht den Ansichten und Überzeugungen von Dub3, den rechtlichen Vertretern und/oder Vertriebspartnern. Sofern rechtlich nicht anders geregelt, übernimmt Dub3 keinerlei Haftung für Nutzerkommentare oder sich eventuell infolge der Erscheinung entsprechender Nutzerkommentare auf der Website ergebende Schadensansprüche und/oder Kosten.

                Dub3 behält sich das Recht vor, alle Kommentare zu überprüfen und eventuell zu entfernen, wenn jene als unangemessen, anstößig oder als Verstoß gegen die Allgemeinen Geschäftsbedingungen betrachtet werden können.

                Hiermit versichern Sie uns und stimmen zu, dass:

                Sie über die notwendige Berechtigung, eventuell erforderliche Lizenzen und Einwilligungen verfügen, Kommentare auf unserer Website zu veröffentlichen;
                Ihre Kommentare keine Rechte an geistigem Eigentum oder anderweitige Eigentumsrechte wie Urheberrechte, Patente oder Markenrechte Dritter verletzen;
                Ihre Kommentare kein beleidigendes, verleumderisches, anstößiges, unangemessenes oder anderweitig rechtswidriges Material enthalten, welches eine Verletzung der Privatsphäre darstellt;
                Ihre Kommentare nicht dem Zweck dienen, geschäftliche, kundenorientierte, kommerzielle Aktivitäten oder rechtswidrige Handlungen zu bewerben oder zu fördern;
                Sie gewähren Dub3 hiermit eine nicht-exklusive Lizenz zur Nutzung, Reproduktion, Bearbeitung und Autorisierung anderer zur Nutzung, Reproduktion und Bearbeitung Ihrer Kommentare in allen Formen, Formaten oder Medien.

                Verlinken unserer Inhalte:
                Folgende Institutionen dürfen ohne vorherige schriftliche Zustimmung einen Link auf unsere Website erstellen:

                Regierungsbehörden;
                Suchmaschinen;
                Nachrichtenagenturen;
                Online-Verzeichnisse dürfen in ihrer Auflistung einen den anderen dort gelisteten Unternehmen entsprechenden Link zu unserer Website erstellen.
                Akkreditierte Unternehmen. Ausgenommen sind werbende gemeinnützige Organisationen, Shops von Wohltätigkeitsorganisationen und Spendenorganisationen, die Geld für wohltätige Zwecke sammeln. Diese dürfen nicht auf unsere Website verlinken.
                Diese Organisationen dürfen eine Verlinkung zu unserer Startseite, unseren Publikationen oder anderen Informationen der Website erstellen, sofern die Verlinkung: (a) in keiner Weise irreführend ist; (b) nicht irrtümlicherweise Förderung, Unterstützung oder Zustimmung in Bezug auf die verlinkenden Partei oder ihre Produkte und/oder Dienstleistungen andeutet; und (c) in den Kontext der zu verlinkenden Website passt.

                Linkanfragen folgender Arten von Organisation können in Betracht gezogen und genehmigt werden:

                allgemein bekannte Verbraucher- und/oder Geschäftsinformationsquellen;
                dot.com Community-Websites;
                Verbände oder andere Einrichtungen, die Wohltätigkeitsorganisationen vertreten;
                Online-Verzeichnisse;
                Internetportale;
                Wirtschaftsprüfungs-, Rechts- und Beratungsunternehmen und
                Bildungsinstitute und Handelsverbände.
                Wir werden Linkanfragen von solchen Organisationen genehmigen, sofern: (a) der Link keine negativen Auswirkungen für uns oder unsere akkreditierten Unternehmen hat; (b) es in der Vergangenheit zu keinem Zeitpunkt zu Unstimmigkeiten mit der Organisation kam; (c) die Vorteile der Sichtbarkeit des Links trotz fehlender Nennung von Dub3 überwiegen und (d) der Link im Kontext allgemeiner Ressourceninformationen steht.

                Diese Organisationen dürfen eine Verlinkung zu unserer Startseite erstellen, sofern die Verlinkung: (a) in keiner Weise irreführend ist; (b) nicht irrtümlicherweise Förderung, Unterstützung oder Zustimmung in Bezug auf die verlinkenden Partei oder ihre Produkte und/oder Dienstleistungen andeutet; und (c) in den Kontext der zu verlinkenden Website passt.

                Wenn Sie zu den in Punkt 2 gelisteten Organisationen gehören und an einer Verlinkung mit unserer Website interessiert sind, senden Sie uns bitte eine E-Mail an Dub3 unter Angabe Ihres Namens, des Namens Ihrer Organisation, Ihrer Kontaktdaten, Ihrer Website-URL, der Liste aller URLs, von denen Sie auf unsere Website verlinken möchten sowie der Liste aller URLs auf unserer Website auf die Sie verlinken möchten. Wir werden uns bemühen, Ihnen innerhalb von 2 bis 3 Wochen zu antworten.

                Organisationen, die eine Genehmigung erhalten, dürfen unsere Website wie folgt verlinken:

                Unter Verwendung unseres Unternehmensnamens oder
                Unter Verwendung des Uniform Resource Locator (URL), mit dem verknüpft wird oder
                Unter Verwendung anderer Beschreibungen unserer Website oder der Materialien, auf die verlinkt wird auf eine Art, dass die Beschreibungen dem Kontext und Inhalt auf der verlinkten Website entsprechen.
                Ohne Lizenzvereinbarung zur Markennutzung dürfen weder das Logo von Dub3 noch andere Grafikelemente zur Verlinkung verwendet werden.

                Haftung für Inhalte:
                Wir übernehmen keine Haftung für Inhalte, die auf Ihrer Website erscheinen. Sie erklären sich hiermit einverstanden, uns hinsichtlich aller auf Ihrer Website erhobenen Ansprüche schadlos zu halten. Auf keiner Website dürfen Links erscheinen, deren Inhalte als verleumderisch, obszön oder kriminell gewertet werden können oder gegen Rechte verstoßen, sie verletzen oder sonstige Verstöße und Verletzung von Rechten Dritter befürworten.

                Rechtsvorbehalt:
                Wir behalten uns das Recht vor, jederzeit und nach eigenem Ermessen zu verlangen, dass Sie alle Links oder einen bestimmten Link zu unserer Website entfernen. Sie erklären sich damit einverstanden, einer solchen Aufforderung umgehend nachzukommen und alle Links zu unserer Website unverzüglich zu entfernen. Wir behalten uns außerdem das Recht vor, die Allgemeinen Geschäftsbedingungen und die Verlinkungsrichtlinien jederzeit ändern zu können. Wenn Sie weiterhin auf unsere Website verlinken, stimmen Sie den Verlinkungsrichtlinien in diesen Allgemeinen Geschäftsbedingungen zu. Indem Sie weiterhin auf unsere Website verlinken, erklären Sie sich damit einverstanden, an diese Verlinkungsbedingungen gebunden zu sein und sie einzuhalten.

                Entfernung von Links von unserer Website:
                Bitte kontaktieren Sie uns jederzeit, wenn Sie einen Link auf unserer Website oder eine verlinkte Website aus irgendeinem Grund als unangemessen betrachten. Anfragen zur Link-Entfernung werden von uns geprüft, wir sind jedoch nicht verpflichtet, ihnen nachzukommen oder auf Anfragen direkt zu antworten.

                Wir übernehmen keine Garantie weder für die Vollständigkeit oder Richtigkeit der Informationen auf dieser Website, noch dass die Website verfügbar bleibt und das Material auf der Website auf dem neuesten Stand gehalten wird.

                Haftungsausschluss:
                Soweit dies nach geltendem Recht zulässig ist, schließen wir alle Zusicherungen, Gewährleistungen und Bedingungen im Zusammenhang mit unserer Website und der Nutzung dieser Website aus). Nichts in diesem Haftungsausschluss soll:

                unsere oder Ihre Haftung für Tod oder Körperverletzung infolge von Fahrlässigkeit einschränken oder ausschließen;
                unsere oder Ihre Haftung für Betrug oder betrügerische Falschdarstellung einschränken oder ausschließen;
                unsere oder Ihre Haftung in einer Weise beschränken, die nach geltendem Recht nicht zulässig ist oder
                irgendeine unserer oder Ihrer Haftungen ausschließen, die nach geltendem Recht nicht ausgeschlossen werden können.
                Die in diesem Abschnitt und an anderer Stelle in diesem Haftungsausschluss dargelegten Haftungsbeschränkungen und -ausschlüsse: (a) unterliegen dem vorhergehenden Absatz und (b) gelten für alle Haftungen, die sich aus dem Haftungsausschluss oder in Bezug auf den Gegenstand dieses Haftungsausschlusses ergeben, einschließlich Haftungen aus Vertrag, unerlaubter Handlung und aufgrund der Verletzung gesetzlicher Pflichten.

                Solange die Website und die Informationen und Dienstleistungen auf dieser Website kostenlos zur Verfügung gestellt werden, übernehmen wir keine Haftung für Verluste oder Schäden jeglicher Art.
            </p>
        </div>
    )
}