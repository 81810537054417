/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()

  const firstLight = useRef()
  const secondLight = useRef()

  useLayoutEffect(() => {

    firstLight.current.target.position.set(1.17, 0.8, 8.17)
    firstLight.current.target.updateMatrixWorld()

    secondLight.current.target.position.set(-1.17, 0.8, 8.17)
    secondLight.current.target.updateMatrixWorld()
  })

  const { nodes, materials } = useGLTF('/gltfs/plate.glb')
  return (
    <group ref={group} {...props} dispose={null}>
  
      <spotLight ref={firstLight} intensity={300} angle={0.2} penumbra={0.2} decay={2} position={[1.22, 2.57, 8.18]} rotation={[-Math.PI / 2, 0, 0]} />
      <spotLight ref={secondLight} intensity={300} angle={0.2} penumbra={0.2} decay={2} position={[-1.22, 2.57, 8.18]} rotation={[-Math.PI / 2, 0, 0]} />

      <mesh geometry={nodes.Cylinder003.geometry} material={nodes.Cylinder003.material} position={[1.22, 0.05, 8.18]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.26, -0.01, -0.26]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={nodes.Cylinder004.material} position={[1.22, 0.07, 8.18]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.22, -0.01, -0.22]} />
      <mesh geometry={nodes.Cylinder005.geometry} material={nodes.Cylinder005.material} position={[1.22, 0.09, 8.18]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.18, -0.01, -0.18]} />
      <mesh geometry={nodes.Cylinder006.geometry} material={materials.Material} position={[1.22, -0.3, 8.18]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.11, -0.01, -0.11]} />
    </group>
  )
}

useGLTF.preload('/gltfs/plate.glb')
