import React, { useRef, useEffect, useState } from 'react'
const introVideoSrc = "./gmintros/guten-morgen-intro-mobile-p1.mp4"

export default function VideoTesting() {
  
  const video = useRef(null)
  const [renderVideo, setRenderVideo] = useState(false)

  useEffect(() => {
     attemptPlay()
  })

  const attemptPlay = () => {
    video &&
      video.current &&
      video.current.play().catch(error => {
        console.error("Error attempting to play", error)
      })
  }

  return (
      <video 
        playsInline={true}
        loop={true}
        muted={true}
        alt="Intro video mobile" 
        className='gmIntroVideo' 
        ref={video}>

          <source 
              src={ introVideoSrc } 
              type="video/mp4"/>
              Your browser does not support HTML5 video. 

      </video> 
    )
}
