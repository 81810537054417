/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/gltfs/fontsingletteranimation.gltf')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh name="Text001" geometry={nodes.Text001.geometry} material={materials['Material.006']} position={[-3.87, 17.56, 0.76]} rotation={[Math.PI / 2, 0, -0.73]} scale={[0.77, 0.77, 1.71]} />
        <mesh name="Text002" geometry={nodes.Text002.geometry} material={materials['Material.006']} position={[2.6, 17.56, 0.76]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[0.77, 0.77, 1.71]} />
        <mesh name="Text003" geometry={nodes.Text003.geometry} material={materials['Material.006']} position={[0.41, 12.97, 0.76]} rotation={[Math.PI / 2, 0, 0.71]} scale={[0.77, 0.77, 1.71]} />
        <mesh name="Text004" geometry={nodes.Text004.geometry} material={materials['Material.006']} position={[-0.61, 19.84, 0.76]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[0.77, 0.77, 1.71]} />
      </group>
    </group>
  )
}

useGLTF.preload('/fontsingletteranimation.gltf')
