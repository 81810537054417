/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Model({ ...props }) {
  const group = useRef()
  const tri = useRef()
  const { nodes, materials } = useGLTF('/gltfs/tri.glb')

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    tri.current.rotation.y = 0.5 - Math.sin(t / 3)
    tri.current.position.y = 0.85 + (Math.sin(t / 2) / 20)
  })

  return (
    <group ref={group} {...props} dispose={null} /* onClick={(e) => console.log('Clicked tri')} */>
      <mesh geometry={nodes.Cube006.geometry} ref={tri} material={materials['Material.007']} position={[-1.23, 0.81, 8.17]} rotation={[0, -Math.PI / 4, 0]} scale={0.18} />
 {/*      <mesh position={[-1.23, 0.81, 8.17]} rotation={[0, -Math.PI / 4, 0]} scale={0.5} >
        <boxBufferGeometry />
        <meshBasicMaterial visible={false}/>
      </mesh> */}
    </group>
  )
}

useGLTF.preload('/gltfs/tri.glb')
