import React, {Component} from 'react'
import * as THREE from "three"
import Experience from '../Experience'

export default class Environment extends Component {
    constructor() {

        super()
        
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setLighting()
    }

    setLighting = () => {
        
        this.directionalLight = new THREE.DirectionalLight("#FFFFFF", 10)
        this.directionalLight.castShadow = true
        this.directionalLight.shadow.camera.far = 20
        this.directionalLight.shadow.mapSize.set(2048, 2048)
        this.directionalLight.shadow.normalBias = 0.05

        /* const helper = new THREE.CameraHelper( this.directionalLight.shadow.camera );
        this.scene.add( helper ); */

        this.directionalLight.position.set(-3, 7.5, 3)
        this.scene.add(this.directionalLight)

        this.ambientLight = new THREE.AmbientLight("#FFFFFF", 3)
        this.scene.add(this.ambientLight)
    }
}