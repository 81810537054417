/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltfs/glass.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.glassback.geometry} position={[0, 1.69, 10.28]} rotation={[0, 0, -Math.PI]} scale={[-1.75, -1.34, -0.03]}>
        <meshPhysicalMaterial depthWrite={false} transmission={2} thickness={5} roughness={0.1} />

      </mesh>
    </group>
  )
}

useGLTF.preload('/glass.glb')
