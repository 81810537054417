/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/gltfs/doors.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh name="doorleft" geometry={nodes.doorleft.geometry} material={materials['Material.020']} position={[0.48, 0.74, 1.2]} scale={[0.53, 0.53, 0.01]}>
          <mesh name="Cube018" geometry={nodes.Cube018.geometry} material={materials['Material.003']} position={[-0.74, -0.12, -0.77]} scale={[0.01, 0.01, 1.44]} />
        </mesh>
        <mesh name="doorright" geometry={nodes.doorright.geometry} material={materials['Material.020']} position={[-0.48, 0.73, 1.2]} scale={[0.53, 0.53, 0.01]}>
          <mesh name="Cube019" geometry={nodes.Cube019.geometry} material={materials['Material.003']} position={[0.74, -0.1, -1.07]} scale={[0.01, 0.01, 1.44]} />
        </mesh>
        <mesh name="doortop" geometry={nodes.doortop.geometry} material={materials['Material.020']} position={[0, 0.53, 1.2]} scale={[0.53, 0.53, 0.01]} />
      </group>
    </group>
  )
}

useGLTF.preload('/doors.glb')
