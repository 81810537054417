import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
    isMobileUser: false,
    timoutOneExpired: false,
    introAnimationPlayed: false,
    animationIndex: 0,
    fiberLoaded: false,
    startExperience: false,
    saveFrames: true,
    screenSpaceReflections: true,
    createSound: false,
    useLerp: true,
});

export { useGlobalState, setGlobalState};