/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/gltfs/digitaldesignstudio.gltf')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh name="Text006" geometry={nodes.Text006.geometry} material={materials['Material.009']} position={[-0.01, 16.42, 0.03]} rotation={[Math.PI / 2, 0, 3.14]} scale={0.05} />
      </group>
    </group>
  )
}

useGLTF.preload('/digitaldesignstudio.gltf')
