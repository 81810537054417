import React, {useEffect, Component} from 'react'
import { useGlobalState } from '../../state'
import axios from 'axios'
import gsap from 'gsap'

import { FaTwitter, FaLinkedinIn, FaBehance } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

import '../../resources/styling/landingPageStyling.css'

import Experience from './Experience/Experience.js'
import gsapCore from 'gsap/gsap-core';

export default class WebglCanvas extends Component { 


    componentDidMount() {

        const experience = new Experience(this.mount)

        this.footerOpen = false

        /* play first Fade in animation */

        this.openingAnimationTimelineAuto = gsap.timeline({defaults:{ duration: .5}})
        this.openingAnimationTimelineAuto
            .fromTo(".hero-main-title", { scale: 1.5, opacity: 0}, { scale: 1, opacity: 1}, '1')

 
        this.contactFooterTimeline = gsap.timeline({paused: true})
        this.contactFooterTimeline
            .to(".transitionSlide", {top: "0vh", duration: .5, ease: "expo.out"})
            .to(".dub3Logo", {opacity: 0, duration: .25, ease: "expo.out", delay: '-.5'})
            .to(".contactFooterContainer", {top: "0vh", duration: 1, ease: "expo.out"})
            .to(".dub3NavLink", {opacity: 0, visibility: "hidden", duration: .5, ease: "power1.out", delay: '-1'})
            .to(".socialIconContainer", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
            .to(".closeIconContainer", {opacity: 1, visibility: "visible", duration: .5, ease: "power1.out", delay: '-1'})
            .fromTo(".contactHeadline", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'})
            .fromTo(".footerFormContainer", {opacity: 0, x: -100}, {opacity: 1, x: 0, duration: .5, ease: "power1.out", delay: '-.25'})   
    }

    playContactAnimation = () => {
        if(this.footerOpen) {
            this.contactFooterTimeline.reverse()
            this.footerOpen = false
        } else {
            this.contactFooterTimeline.play()
            this.footerOpen = true
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const email = event.target.elements.email.value // from elements property
        const msg = event.target.inqMessage.value         

        axios.post('https://fatp-crud-api.herokuapp.com/inq', {service: "Cover", email: email, message: msg}); 

        document.querySelector(".successMessage").classList.add("active")
    }

    closeCookieNotice = (event) => {
        gsap.fromTo(".cookieNoticeContainer", { right: '0', duration: 1}, { right: '-50%', duration: 1})
    }

    render() {
        return (
            <>
            <div className='landing-wrapper'>

                <div 
                    className="webglCanvas"
                    ref={mount => { this.mount = mount}}
                />

                <div className='page'> 

                    <div className='page-wrapper'>

                        {/* -----------------
                            NAVIGATION
                        ---------------------*/}

                        <nav id="dub3NavContainer">

                            <img className="dub3Logo" src="./w3silver.png" /> 

                            <span className='dub3NavLink' onClick={() => {this.playContactAnimation()}}>Contact</span>

                            <div className='closeIconContainer'>
                                <GrClose className='closeIcon' onClick={() => {this.playContactAnimation()}}/>
                            </div>

                            <div className='socialIconContainer'> 
                                <FaTwitter className='footerSocialIcon'/>
                                <FaBehance className='footerSocialIcon'/>
                                <FaLinkedinIn className='footerSocialIcon'/>
                             </div>

                        </nav>


                      {/* -----------------
                            Cookies
                        ---------------------*/}

                        <div className="cookieNoticeContainer">
                            <p>We use coookies to give you the best experience on our site</p>
                            <div className='cookieLinkContainer'>  
                                <a className='callToActionLink' onClick={() => {this.closeCookieNotice()}}>bet</a> 
                                <a className='callToActionLink'>privacy policy</a>
                            </div>
                        </div>

                        {/* -----------------
                            HERO SECTION 
                        ---------------------*/}

                        <section className='hero'>
                            <div className='hero-wrapper'>

                                <div className='hero-main'>
                                    <h1 className='hero-main-title'>Do you remember the last time <br /> you couldn't stop scrolling through a website?</h1>
                                </div>

                            </div>
                        </section>

                        <div className='first-move section-margin'></div>

                        {/* -----------------
                            FIRST SECTION 
                        ---------------------*/}

                        <section className='landing-section section right' id="second-move">

                            <h2 className='sectionTitle'>
                                what we on
                            </h2> 

                            <p className='sectionText'> 
                                dub3 is pushing the boundaries of combining 3D and web by creating memorable user experiences tailored to your brand
                            </p>

                        </section>

                        <div className='section-margin third-move second-move'></div>

                        {/* -----------------
                            SECOND SECTION 
                        ---------------------*/}

                        <section className='landing-section section left' id="third-move">
                            
                            <h2 className='sectionTitle'>
                                what we create
                            </h2> 

                            <p className='sectionText'> 
                                unforgettable 3D scences that catch the users attention so your product won't be forgotten
                            </p>

                            <br />

                            <p className='sectionText'> 
                                interactive visual content the world hasn't seen before
                            </p>

                            <br />

                            <p className='sectionText'> 
                            </p>
                            
                        </section>

                        <div className='section-margin'></div>

                        {/* -----------------
                            THIRD SECTION 
                        ---------------------*/}

                        <section className='landing-section section right' id="third">
                            
                            <h2 className='sectionTitle'>
                                what we got
                            </h2> 

                            <p className='sectionText'> 
                                eye catching websites displaying products or services in interactive 3D scenes, audibly backed by custom sound design
                            </p>
                            
                        </section>

                        <div className='section-margin fade-in-cta'></div>

                        
                        <section className='CTA'>
                            <div className='CTA-wrapper'>

                                <div className='CTA-main'>
                                    <h1 className='callToAction-main-title'>don't let a boring website<br />prevent you from scaling your business</h1>
                                    <a className='callToActionLink' onClick={() => {this.playContactAnimation()}}>get in touch</a>
                                </div>

                            </div>
                        </section>

                        {/* -----------------
                            CONTACT FORM FOOTER
                            --------------------- */}

                        <section className="dubContactWrapper">

                            <div className='transitionSlide'>

                            </div>

                            <div className='contactFooterContainer'>

                                <h1 className='contactHeadline'>Combine web and 3D with us, dub3</h1>

                                <div className="footerFormContainer"> 

                                    <div>
                                        <p>INQUIRIES</p>
                                        <br></br>
                                    </div> 

                                    <div className="footerFormElementContainer">
                                        <div className="contactFormElement">
                                            <form className="dubFormContainer" onSubmit={this.handleSubmit}>
                                                <input type="email" name="email" placeholder='E-Mail'></input> 
                                                <textarea required name="inqMessage" rows="5" cols="25" placeholder='leave a message'></textarea> 
                                                <button className='formInputButton'>send request</button>
                                                <p className='successMessage'>thank you for your message</p>
                                            </form> 
                                        </div>

                                        <div className="contactFormElement">
                                            <p>Email us</p>
                                            <a className="emailLink" href="mailto:inquiries@dub3.studio">inquiries@whead3.com</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section> 
                        

                    </div>
                </div>
            </div>
            </>
        )
    }

}