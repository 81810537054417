import React, {useState, useEffect} from 'react'
import "../../resources/styling/preloadStyling.css"
import gsap from 'gsap';
import Lottie from "lottie-react";
import SplitType from 'split-type'

import tapAnimationData from "../../resources/lotties/84088-tapping.json";
import wavesData from "../../resources/lotties/line-loading.json";
import bgData from "../../resources/lotties/curves.json";
import preload from "../../resources/lotties/preloadcentered.json";

import LandingPage from './LandingPage'

import { IoHeadsetSharp } from 'react-icons/io5';
import { render } from '@testing-library/react';
import FiberCanvas from './FiberCanvas';
import useStore from "./store/useStore"

import DesktopNotice from "../pages/DesktopNotice"

let mounted = false
let renderCheck = true

const Preload = () => {

    const [preloadMounted, setPreloadMounted] = useState(false)

    const isMobileUser = useStore((state) => state.isMobileUser)
    const setMobileUserIsTrue = useStore((state) => state.setMobileUserIsTrue)
    const setMobileUserIsFalse = useStore((state) => state.isMobilsetMobileUserIsFalseeUser)

    const fiberLoaded = useStore((state) => state.fiberLoaded)
    const setFiberLoadedIsTrue = useStore((state) => state.setFiberLoadedIsTrue)
    const setFiberLoadedIsFalse = useStore((state) => state.setFiberLoadedIsFalse)

    const saveFrames = useStore((state) => state.saveFrames)
    const setSaveFramesIsTrue = useStore((state) => state.setSaveFramesIsTrue)
    const setSaveFramesIsFalse = useStore((state) => state.setSaveFramesIsFalse)

    const startExperienceGOO = useStore((state) => state.startExperience)
    const setStartExperienceIsTrue = useStore((state) => state.setStartExperienceIsTrue)
    const setstartExperienceIsFalse = useStore((state) => state.setstartExperienceIsFalse)

    const createSound = useStore((state) => state.createSound)
    const setCreateSoundIsTrue = useStore((state) => state.setCreateSoundIsTrue)
    const setCreateSoundIsFalse = useStore((state) => state.setCreateSoundIsFalse)

    const mainHeading = new SplitType('.heroHeading')
    const subHeading = new SplitType('.heroSubHeading')


    useEffect(() => {

        //localStorage.clear()
       
        if(!startExperienceGOO) {
            console.log("save frames")
            setSaveFramesIsTrue()
        }

        if(!mounted) {
            setPreloadMounted(true)
            mounted = true
        }

        if(fiberLoaded && renderCheck) {
            gsap.fromTo(".tapAnimation", {opacity: 1}, {opacity: 0, duration: 1}, '0.5')
            gsap.fromTo(".startNotice", {y: '30vh', opacity: 0}, {y: 0, opacity: 1, duration: 1}, '0.5')
            renderCheck = false
        } 
    })

    const startExperience = () => {

        const startExperienceTimeline = new gsap.timeline({defaults: {duration: 1.5, ease: "expo.out"}, 
            onComplete: function () {

                document.querySelector(".preloadWrapper").classList.add("elementHidden")

                if(!localStorage.consent) {
                    document.querySelector(".cookieNoticeContainer").classList.add("animateCookieNotice")
                }
            },
            onStart: function () {
                setStartExperienceIsTrue()
            },
            paused: true
        })

        startExperienceTimeline
            .to(".bgCurvesAnimation", {opacity: 0, duration: 0}, 0)
            .to(".btn", {y: '25vh', duration: 0.5}, '0.5')
            .to(".overlayUpper", {y: '-50vh'}, '1')
            .to(".overlayLower", {y: '50vh'}, '1')
            .to(".landingOverlay", { opacity: 0,}, '0')
            .fromTo(".heroHeading", { opacity: 0}, { opacity: 1, duration: 0.7}, '2.5')
            .fromTo(".heroSubHeading", { opacity: 0}, { opacity: 1, duration: 0.7}, '3')
/*             .to(".heroHeading .line", 1.8, {
                opacity: 1,
                ease: "power4.out",
                delay: -0.7,
                skewY: 5,
                stagger: {
                  amount: 0.5
                }
              })
            .fromTo(".fadeInUp .line", 1.8, {
                y: -200,
                opacity: 0,
                ease: "power4.out",
                delay: -0.7,
                skewY: 5,
                stagger: {
                  amount: 0.5
                }       
              }, {
                y: 0,
                opacity: 1,
                ease: "power4.out",
                delay: -0.7,
                skewY: 0,
                stagger: {
                  amount: 0.5
                }}) */

              
              /* .to(".line .fadeInUp", .2, {
                y: 0,
                opacity: 1,
                ease: "power4.out",
                delay: 0,
                skewY: 7,
                stagger: {
                    amount: 0.1
                }
            }) */
            .fromTo("#contactLink", {opacity: 0, x: 160}, {opacity: 1, x: 0, duration: 0.3}, '4')
            .fromTo("#aboutLink", {opacity: 0, x: 260}, {opacity: 1, x: 0, duration: 0.3}, '4')
            .fromTo(".mainHeaedrLink", {x: -170}, {x: 0, duration: 0.3}, '4')
            .fromTo(".indexContainer", {x: 100}, {x: 0, duration: 0.3}, '4')
            .fromTo(".audioControls", {x: -100}, {x: 0, duration: 0.3}, '4')
            .to(".landingScrollToPlayNoticeWrapper", {opacity: 1, duration: 0.3})

        if(fiberLoaded) {
            startExperienceTimeline.play()
            setCreateSoundIsTrue()
            setSaveFramesIsFalse()
        }
    }

    return (
        <>
            <div className='preloadWrapper' >

                <div className="overlayUpper"></div>
                <div className="overlayLower"></div>

                {/* <div className="overlayFrosted"></div> */}

                <div className='preloadOverlay'>

                    <div class="btn btn-six startNotice" onClick={() => {startExperience()}}>
                        <span>enter</span>
                    </div>
                
                </div>

                <div className="lottieOverlay">
                    <Lottie className="tapAnimation" animationData={tapAnimationData} loop={true} />    
                </div>

                {preloadMounted ? 
                    <div className='preloadBackground'>
                        <Lottie className="bgCurvesAnimation" animationData={preload} loop={true} />  
                    </div>
                : '' }
               
            </div>

            {isMobileUser && preloadMounted ? <FiberCanvas /> : <FiberCanvas /> }

        </>
    );
};

export default Preload;