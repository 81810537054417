/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef, useEffect } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'
import { useThree, useFrame } from '@react-three/fiber'
import useStore from "../web3D/store/useStore"

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/gltfs/camera.gltf')
  const { actions } = useAnimations(animations, group)

  const useLerp = useStore((state) => state.useLerp)
  const use3DLerp = useStore((state) => state.use3DLerp)

  const vec = new THREE.Vector3()
  const { camera, mouse } = useThree()
  
  const fov = 4.5

  useFrame(() => {

      if(useLerp) {
        camera.position.lerp(vec.set(mouse.x * 0.25, camera.position.y, camera.position.z), 0.05)
      } else if(use3DLerp) {
        camera.position.lerp(vec.set(mouse.x * 0.25, 0.7 - mouse.y * 0.15, camera.position.z), 0.05)
      } else {
        camera.position.set(camera.position.x, camera.position.y, camera.position.z)
      }

  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <PerspectiveCamera name="Camera" {...props} far={1000} near={0.1} fov={fov} position={[0, 18.02, -0.01]} rotation={[-1.59, 0, -3.14]} />
      </group>
    </group>
  )
}

useGLTF.preload('/camera.gltf')
