import React, {Component} from 'react'
import * as THREE from "three"

import EventEmitter from "reactjs-eventemitter"
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader"
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader"

import Experience from "../Experience"
import assets from './assets'

export default class Resources extends Component {

    constructor(assets) {

        super()

        this.experience = new Experience()
        this.renderer = this.experience.renderer

        this.assets = assets

        this.items =  {}
        this.queue = this.assets.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }

    setLoaders = () => {
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader()
        this.loaders.dracoLoader = new DRACOLoader()
        this.loaders.dracoLoader.setDecoderPath("/draco/")
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader) 
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
    }

    startLoading = () => {
        for(const asset of this.assets) {
            if(asset.type === "glbModel") {
                this.loaders.gltfLoader.load(asset.path, (file) => {
                    this.singleAssetLoaded(asset, file)
                })
            } else if(asset.type === "gltfModel") {
                this.loaders.gltfLoader.load(asset.path, (file) => {
                    this.singleAssetLoaded(asset, file)
                })
            } else if(asset.type === "envMap") {

                // ----------------------------
                // Load texture as ENVIRONMENT MAP

                this.loaders.cubeTextureLoader.setPath(asset.path)
                this.textureCube = this.loaders.cubeTextureLoader.load([asset.file]) 
                this.textureCube.encoding = THREE.sRGBEncoding

                this.singleAssetLoaded(asset, this.textureCube)
                
            } else if( asset.type === "videoTexture") {
                // * Loading VideoTextures in THREE
                this.video = {}
                this.videoTexture = {}

                this.video[asset.name] = document.createElement("video")
                this.video[asset.name].src = asset.path
                this.video[asset.name].playsInline = true
                this.video[asset.name].autoplay = true
                this.video[asset.name].loop = true
                this.video[asset.name].muted = true
                this.video[asset.name].play()

                this.videoTexture[asset.name] = new THREE.VideoTexture(this.video[asset.name])
                
                //this.video[asset.name].flipY = true
                this.video[asset.name].minFilter = THREE.NearestFilter
                this.video[asset.name].magFilter = THREE.NearestFilter
                this.video[asset.name].generateMipmaps = false
                this.video[asset.name].encoding = THREE.sRGBEncoding

                this.singleAssetLoaded(asset, this.video[asset.name])
            }
        }
    }

    singleAssetLoaded = (asset, file) => {


        this.items[asset.name] = file
        this.loaded++

        if(this.loaded === this.queue){     
            EventEmitter.emit("ready")  
            console.log("all assets are loaded")          
        }
    }
}