import React, {useEffect} from 'react'
import ReactGA from 'react-ga'
import { Routes, Route, Outlet, Link} from "react-router-dom"

import LandingPage from './components/web3D/LandingPage'
import WebGLCanvas from './components/web3D/WebGLCanvas'
import FiberCanvas from './components/web3D/FiberCanvas'
import Preload from './components/web3D/Preload'
import VideoTesting from './components/web3D/VideoTesting.js'
import Contact from './components/pages/Contact/Contact'
import About from './components/pages/About/About'
import Cursor from './components/CustomCursor/Cursor'
import NoMatch from './components/pages/NoMatch'
import Agb from './components/pages/Agb'
import Impressum from './components/pages/Impressum'
import PrivacyPolicy from './components/pages/PrivacyPolicy'

import "@fontsource/dela-gothic-one"

import './App.css'
import useStore from './components/web3D/store/useStore'
import Navigation from './components/Navigation/Navigation'

import CustomCursorManager from './components/CustomCursor/context/manager.tsx'

function App() {

  const isMobileUser = useStore((state) => state.isMobileUser)
  const setMobileUserIsTrue = useStore((state) => state.setMobileUserIsTrue)

  // recognize mobile user


  useEffect(() => {
      if (/Mobi/.test(navigator.userAgent)) {
        setMobileUserIsTrue()
      }
  })

  return (
 
    <CustomCursorManager>

        <div className="App">

              <Cursor />

              <Navigation />

              <Routes>

                  <Route path="/" element={ <Outlet />}>

                      <Route index element={<Preload />} />

                      <Route path="three" element={<WebGLCanvas />} /> 

                      <Route path="fiber" element={<FiberCanvas />} /> 

                      <Route path="video" element={<VideoTesting />} /> 

                      <Route path="contact" element={<Contact />} /> 

                      <Route path="about" element={<About />} /> 

                      <Route path="agb" element={<Agb />} /> 
                      <Route path="impressum" element={<Impressum />} /> 
                      <Route path="privacy-policy" element={<PrivacyPolicy />} />            

                      <Route path="*" element={<NoMatch />} />

                  </Route>

              </Routes>

        </div> 

    </CustomCursorManager>
  )
}

export default App;