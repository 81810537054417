import React, {Component} from 'react'
import * as THREE from "three"

import Experience from './Experience'

export default class Renderer extends Component {

    constructor() {

        super()

        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.camera = this.experience.camera

        this.setRenderer()
    }

    setRenderer = () => {
        this.renderer = new THREE.WebGLRenderer({antialias: true })

        this.canvas.appendChild(this.renderer.domElement)

        this.renderer.physicallyCorrectLights = true 
    
        //Encoding & color tones 
        this.renderer.outputEncoding = THREE.sRGBEncoding
        this.renderer.toneMapping = THREE.CineonToneMapping
        this.renderer.toneMappingExposure = 2.75 

        //enabling shadows and setting type
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFShadowMap
 
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        //this.renderer.setViewport(this.sizes.pixelRatio)
    } 

    resize = () => {
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setViewport(this.sizes.pixelRatio)
    }

    update = () => {
        this.renderer.setViewport(0, 0, this.sizes.width, this.sizes.height)
        this.renderer.render(this.scene, this.camera.perspectiveCamera)

        // Second screen for camera testing
        
/*      this.renderer.setScissorTest(true)
        this.renderer.setViewport(
            this.sizes.width - this.sizes.width / 3, 
            this.sizes.height - this.sizes.height / 3,
            this.sizes.width / 3,
            this.sizes.height / 3)

        this.renderer.setScissor(
            this.sizes.width - this.sizes.width / 3, 
            this.sizes.height - this.sizes.height / 3,
            this.sizes.width / 3,
            this.sizes.height / 3)    

        this.renderer.render(this.scene, this.camera.orthographicCamera)  */

        this.renderer.setScissorTest(false)
    }
}