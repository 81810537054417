import React, {Component} from 'react';
import * as THREE from "three"
import EventEmitter from "reactjs-eventemitter";

import Sizes from './Utils/Sizes'
import Time from './Utils/Time'
import Resources from './Utils/Resources';
import assets from './Utils/assets';

import Camera from './Camera'
import Renderer from './Renderer'
import World from './World/World';

export default class Experience extends Component {

    static instance = undefined

    constructor (mount) {

        super(mount)

        if(Experience.instance != undefined) {
            return Experience.instance
        }

        Experience.instance = this

        this.canvas = mount
        this.scene = new THREE.Scene()

        //var bgTexture = new THREE.TextureLoader().load('res/spotd1zzz.jpg');
        var whiteBgColor = new THREE.Color( 0xFFFFFF)
        var blackBgColor = new THREE.Color( 0x000000)

        /* var bgTexture = new THREE.TextureLoader().load('resources/texture/somenotnone-texture.png');
        console.log(bgTexture)
        this.scene.background = bgTexture; */

        this.scene.background = blackBgColor

        this.time = new Time() 
        this.sizes = new Sizes()
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.resources = new Resources(assets)

        this.world = new World()

        EventEmitter.subscribe('resize', event => { this.resize() })
        EventEmitter.subscribe('update', event => { this.update() })
    }

    resize = () => {
        this.camera.resize()
        this.world.resize()
        this.renderer.resize()
    }

    update = () => {
        this.camera.update() 
        this.world.update()
        this.renderer.update()
    }
}