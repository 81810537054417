/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltfs/toweremission.gltf')
  return (
    <group ref={group} {...props} dispose={null} position={[0,0,-0.1]}>
      <mesh geometry={nodes.emission047.geometry} material={materials['Material.010']} position={[0, 7.64, 0.46]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission048.geometry} material={materials['Material.010']} position={[0, 8.38, 1.86]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission049.geometry} material={materials['Material.010']} position={[0, 8.25, 1.66]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission050.geometry} material={materials['Material.010']} position={[0, 8, 1.26]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission051.geometry} material={materials['Material.010']} position={[0, 8.12, 1.46]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission052.geometry} material={materials['Material.010']} position={[0, 7.85, 0.86]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission053.geometry} material={materials['Material.010']} position={[0, 7.73, 0.66]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission054.geometry} material={materials['Material.010']} position={[0, 7.92, 1.06]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission055.geometry} material={materials['Material.010']} position={[0, 7.55, 0.26]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission057.geometry} material={materials['Material.010']} position={[0, 7.46, 0.06]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
    </group>
  )
}

useGLTF.preload('/toweremission.gltf')
