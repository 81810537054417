import React, {Component} from 'react'
import * as THREE from "three"
import { Scene } from 'three'

import Experience from '../Experience'

export default class Cube extends Component {

    constructor() {

        super()

        this.experience = new Experience()
        this.scene = this.experience.scene

        // * BOX GEOMETRY
        var bufferGeometry = new THREE.BoxBufferGeometry(2,2,2)
        var material = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            wireframe: true
        })

        this.cube = new THREE.Mesh(bufferGeometry, material)

        this.scene.add(this.cube)
    }

    resize = () => {

    }

    update = () => {
       
    }
}