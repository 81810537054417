/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/gltfs/churchemission.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.emission.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission001.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission002.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission003.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission004.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission005.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission006.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission007.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission008.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission009.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission010.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission011.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission012.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission013.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission014.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission015.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission016.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission017.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission018.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission019.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission020.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission021.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission022.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission023.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission024.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission025.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission026.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission027.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission028.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission029.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission030.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission031.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission032.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission033.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission034.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission035.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission036.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission037.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission038.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission039.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission040.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission041.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission042.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
 {/*      <mesh geometry={nodes.emission043.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission044.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission045.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission046.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission047.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission048.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission049.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission050.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission051.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission052.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission053.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission054.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission055.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission057.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission058.geometry} material={materials['Material.010']} position={[0, 2.67, 1.53]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
      <mesh geometry={nodes.emission059.geometry} material={materials['Material.010']} position={[0, 2.67, 1.61]} rotation={[0, 0, -Math.PI]} scale={[-1, -4.86, -0.05]} />
 */}    </group>
  )
}

useGLTF.preload('/churchemission.gltf')
